import { all, apply, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { IActionType, IAxiosResponse } from '../root.types'
import { open } from '../snack.bar/actions'
import { SnackBarMessageType } from '../../../components/snackbar'
import {
    CenterTypes,
    IActionAssociateCoordination,
    IActionCreate,
    IActionCreateIndicatorsByRegional,
    IActionDissociate,
    IActionDissociateCoordination,
    IActionFind,
    IActionLoad,
    IActionLoadAssociate,
    IActionLoadCalls,
    IActionLoadCentersByUser,
    IActionLoadCoordinations, IActionLoadIndicatorsByRegional,
    IActionLoadIriByRegional,
    IActionLoadIriByUser,
    IActionLoadServiceLocations,
    IActionRemove
} from './types'

import {
    associatesCoordinationFailure,
    associatesCoordinationSuccess,
    associatesUserFailure,
    associatesUserSuccess,
    createFailure,
    createSuccess,
    dissociatesCoordinationFailure,
    dissociatesCoordinationSuccess,
    dissociatesUserFailure,
    dissociatesUserSuccess,
    findFailure,
    findSuccess,
    loadAssociatesFailure,
    loadAssociatesSuccess,
    loadCentersByUserFailure,
    loadCentersByUserSuccess,
    loadCoordinationsFailure,
    loadCoordinationsSuccess,
    loadFailure,
    loadIndicatorsFailure,
    loadIndicatorsSuccess,
    loadIriFailure,
    loadIriSuccess,
    loadLocationsFailure,
    loadLocationsSuccess,
    loadMoreAssociatesFailure,
    loadMoreAssociatesSuccess,
    loadMoreFailure,
    loadMoreSuccess,
    loadRequest,
    loadSuccess,
    removeFailure,
    removeSuccess,
    saveIndicatorsFailure,
    saveIndicatorsSuccess,
    servicesDispatchesFailure,
    servicesDispatchesSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { loadRequest as loadDeviceRequest } from '../devices/actions'
import { changeBreadCrumbLast } from '../layout/actions'
import centerService from '../../../services/regional.centers'
import serviceDispatchService from '../../../services/service.dispatch'
import User from '../../application/models/users/users'
import RegionalCenter from '../../application/models/regional.center/regional.center'
import localStorageService from '../../../services/local.storage'
import Coordination from '../../application/models/regional.center/coordination'
import Call from '../../application/models/call/call'
import locationsService from '../../../services/service.location'
import ServiceLocation from '../../application/models/regional.center/service.location'
import Iri from '../../application/models/regional.center/iri'
import Indicator from '../../application/models/regional.center/indicator'

function* create(action: IActionType<IActionCreate>) {
    try {
        const { center } = action.payload
        const response: any = yield apply(centerService, centerService.create, [center])
        yield put<any>(createSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REGIONAL_CENTER.CREATE.MESSAGE'))
        yield call(reloadCenters)
    } catch (e) {
        yield put(createFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(
            centerService,
            centerService.getAll,
            [paginator]
        )
        yield put<any>(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

function* getById(action: IActionType<IActionFind>) {
    yield put(changeBreadCrumbLast(''))
    const { id } = action.payload
    try {
        const response: any = yield apply(centerService, centerService.getById, [id])
        yield put(findSuccess(response))
        yield put(changeBreadCrumbLast(response?.name))
    } catch (e) {
        yield put(findFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    try {
        const { center } = action.payload
        const response: any = yield apply(centerService, centerService.update, [center])
        yield put<any>(updateSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REGIONAL_CENTER.UPDATE.MESSAGE'))
        yield call(reloadCenters)
    } catch (e) {
        yield put(updateFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(centerService, centerService.remove, [id])
        yield put<any>(removeSuccess(id))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REGIONAL_CENTER.REMOVE.MESSAGE'))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* loadMore(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<any[]> = yield apply(
            centerService,
            centerService.getAll,
            [paginator]
        )
        yield put<any>(loadMoreSuccess(response))
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* reloadCenters() {
    yield put<any>(loadRequest())
}

function* loadAssociates(action: IActionType<IActionLoadAssociate>) {
    const { userType, paginator, centerId } = action.payload
    try {
        const response: IAxiosResponse<User[]> = yield apply(
            centerService,
            centerService.getUsersAssociates,
            [centerId, userType, paginator]
        )
        yield put<any>(loadAssociatesSuccess(userType, response))
    } catch (e) {
        yield put(loadAssociatesFailure(userType))
    }
}

function* loadMoreAssociates(action: IActionType<IActionLoadAssociate>) {
    const { centerId, userType, paginator } = action.payload
    try {
        const response: IAxiosResponse<User[]> = yield apply(
            centerService,
            centerService.getUsersAssociates,
            [centerId, userType, paginator]
        )
        yield put<any>(loadMoreAssociatesSuccess(userType, response))
    } catch (e) {
        yield put(loadMoreAssociatesFailure(userType))
    }
}

function* associateUser(action: IActionType<IActionDissociate>) {
    const { userType, user, centerId } = action.payload
    try {
        yield apply(
            centerService,
            centerService.associateUser,
            [centerId, userType, user?.id || '']
        )
        yield put<any>(associatesUserSuccess(userType, user))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REGIONAL_CENTER.ASSOCIATE.MESSAGE'))
    } catch (e) {
        yield put(associatesUserFailure(userType))
    }
}

function* dissociateUser(action: IActionType<IActionDissociate>) {
    const { userType, user, centerId } = action.payload
    try {
        yield apply(
            centerService,
            centerService.dissociateUser,
            [centerId, userType, user?.id || ''])
        yield put<any>(dissociatesUserSuccess(userType, user))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REGIONAL_CENTER.DISSOCIATE.MESSAGE'))
    } catch (e) {
        yield put(dissociatesUserFailure(userType))
    }
}

function* loadCentersByUser(action: IActionType<IActionLoadCentersByUser>) {
    const { userId, userType, paginator } = action.payload
    try {
        const response: IAxiosResponse<RegionalCenter[]> = yield apply(
            centerService,
            centerService.getCentersByUser,
            [userId, userType, paginator]
        )
        yield put<any>(loadCentersByUserSuccess(response))
    } catch (e) {
        yield put(loadCentersByUserFailure())
    }
}

function* saveCenterSelected(action: IActionType) {
    const { center } = action.payload
    yield apply(
        localStorageService,
        localStorageService.setItem,
        ['centerSelected', JSON.stringify(center)]
    )
}

function* loadCoordinations(action: IActionType<IActionLoadCoordinations>) {
    const { centerId, paginator } = action.payload
    try {
        const response: IAxiosResponse<Coordination[]> = yield apply(
            centerService,
            centerService.getCoordinationsByCenterId,
            [centerId, paginator]
        )
        yield put<any>(loadCoordinationsSuccess(response))
    } catch (e) {
        yield put(loadCoordinationsFailure())
    }
}

function* associateCoordination(action: IActionType<IActionAssociateCoordination>) {
    const { centerId, coordination } = action.payload
    try {
        yield apply(centerService, centerService.associateCoordination, [centerId, `${coordination?.id}`])
        yield put<any>(associatesCoordinationSuccess(centerId, coordination))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.ZONES.ASSOCIATE.MESSAGE'))
    } catch (e) {
        yield put(associatesCoordinationFailure(centerId, `${coordination?.id}`))
    }
}

function* dissociateCoordination(action: IActionType<IActionDissociateCoordination>) {
    const { centerId, coordinationId } = action.payload
    try {
        yield apply(centerService, centerService.dissociateCoordination, [centerId, coordinationId])
        yield put<any>(dissociatesCoordinationSuccess(centerId, coordinationId))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.ZONES.DISSOCIATE.MESSAGE'))
    } catch (e) {
        yield put(dissociatesCoordinationFailure(centerId, coordinationId))
    }
}

function* loadServicesDispatch(action: IActionType<IActionLoadCalls>) {
    const { regionalCenterId, monthYear, coordinations, serviceTypes, sort } = action.payload
    try {
        let coordinationsSelected: string[] = coordinations || []
        if (!coordinations?.length) {
            const listOfCoordinations: IAxiosResponse<Coordination[]> = yield apply(
                centerService,
                centerService.getCoordinationsByCenterId,
                [regionalCenterId]
            )
            coordinationsSelected = listOfCoordinations
                ?.data
                ?.map((coordItem: Coordination) => `${coordItem?.name}`) || []
        }
        const response: IAxiosResponse<Call[]> = yield apply(
            serviceDispatchService,
            serviceDispatchService.getAllByCoordinations,
            [coordinationsSelected, monthYear, serviceTypes, sort]
        )
        yield put<any>(servicesDispatchesSuccess(response))
        const allInstallations: string[] = response.data.map((item: Call) => `${item.affected_device}`)
        const setInstallations = new Set(allInstallations)
        const installations = Array.from(setInstallations)
        yield put<any>(loadDeviceRequest(installations))
    } catch (e) {
        yield put(servicesDispatchesFailure())
    }
}


function* loadServicesLocations(action: IActionType<IActionLoadServiceLocations>) {
    const { coordinations } = action.payload
    try {
        let locations: ServiceLocation[] = []
        for (const coordination of coordinations) {
            const response: IAxiosResponse<ServiceLocation[]> = yield apply(
                locationsService,
                locationsService.getAllByCoordination,
                [coordination]
            )
            locations = locations.concat(response.data)
        }
        yield put(loadLocationsSuccess(locations))
    } catch (e) {
        yield put(loadLocationsFailure())
    }
}

function* loadIri(action: IActionType<IActionLoadIriByRegional>) {
    const { monthYear, regionalCenterId, paginator } = action.payload
    try {
        const response: IAxiosResponse<Iri[]> = yield apply(
            centerService,
            centerService.getIriByCenterId,
            [regionalCenterId, monthYear, paginator]
        )
        yield put(loadIriSuccess(response))
    } catch (e) {
        yield put(loadIriFailure())
    }
}

function* loadIriByUser(action: IActionType<IActionLoadIriByUser>) {
    const { monthYear, regionalCenterId, userId } = action.payload
    try {
        const response: IAxiosResponse<Iri[]> = yield apply(
            centerService,
            centerService.getIriByCenterIdAndUserId,
            [regionalCenterId, userId, monthYear]
        )
        yield put(loadIriSuccess(response))
    } catch (e) {
        yield put(loadIriFailure())
    }
}

function* loadIndicators(action: IActionType<IActionLoadIndicatorsByRegional>) {
    const { regionalCenterId } = action.payload
    try {
        const indicators: Indicator = yield apply(
            centerService,
            centerService.getIndicatorsByRegionalId,
            [regionalCenterId]
        )
        yield put(loadIndicatorsSuccess(regionalCenterId, indicators))
    } catch (e) {
        yield put(loadIndicatorsFailure())
    }
}

function* saveIndicators(action: IActionType<IActionCreateIndicatorsByRegional>) {
    const { regionalCenterId, indicators } = action.payload
    try {
        const saved: Indicator = yield apply(
            centerService,
            indicators?.id ?
                centerService.updateIndicatorsByRegionalId
                : centerService.createIndicatorsByRegionalId,
            [regionalCenterId, indicators]
        )
        yield put(saveIndicatorsSuccess(regionalCenterId, saved))
    } catch (e) {
        yield put(saveIndicatorsFailure())
    }
}

export default function* regionalCenterSaga() {
    return yield all([
        takeLatest(CenterTypes.CREATE_REQUEST, create),
        takeLatest(CenterTypes.LOAD_REQUEST, getAll),
        takeLatest(CenterTypes.FIND_REQUEST, getById),
        takeLatest(CenterTypes.UPDATE_REQUEST, update),
        takeLatest(CenterTypes.REMOVE_REQUEST, remove),
        takeEvery(CenterTypes.LOAD_MORE_REQUEST, loadMore),
        takeEvery(CenterTypes.LOAD_ASSOCIATES_REQUEST, loadAssociates),
        takeEvery(CenterTypes.LOAD_MORE_ASSOCIATES_REQUEST, loadMoreAssociates),
        takeEvery(CenterTypes.ASSOCIATE_USER_REQUEST, associateUser),
        takeEvery(CenterTypes.DISSOCIATE_USER_REQUEST, dissociateUser),
        takeEvery(CenterTypes.LOAD_CENTERS_BY_USER_REQUEST, loadCentersByUser),
        takeEvery(CenterTypes.CHANGE_REGIONAL_SELECTED, saveCenterSelected),
        takeEvery(CenterTypes.LOAD_COORDINATIONS_REQUEST, loadCoordinations),
        takeEvery(CenterTypes.ASSOCIATE_COORDINATIONS_REQUEST, associateCoordination),
        takeEvery(CenterTypes.DISSOCIATE_COORDINATIONS_REQUEST, dissociateCoordination),
        takeEvery(CenterTypes.SERVICES_DISPATCH_REQUEST, loadServicesDispatch),
        takeEvery(CenterTypes.LOAD_LOCATIONS_REQUEST, loadServicesLocations),
        takeEvery(CenterTypes.LOAD_IRI_REQUEST, loadIri),
        takeEvery(CenterTypes.LOAD_IRI_BY_USER_REQUEST, loadIriByUser),
        takeEvery(CenterTypes.LOAD_INDICATORS_REQUEST, loadIndicators),
        takeEvery(CenterTypes.SAVE_INDICATORS_REQUEST, saveIndicators)
    ])
}
