import { Reducer } from 'redux'
import { DevicesTypes, IDeviceState } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, request, success } from '../root.types'
import Device from '../../application/models/call/device'

export const INITIAL_STATE: IDeviceState = {
    list: {
        data: [],
        loading: false,
        success: false,
        error: false
    }
}

export const resetList = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const loadRequest = (state: IDeviceState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (state: IDeviceState = INITIAL_STATE, action: IActionType<{
    data: Device[]
}>) => {
    const { data } = action.payload
    return { ...state, list: success({ ...state.list, data }) }
}

export const loadFailure = (state: IDeviceState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

const reducer: Reducer<IDeviceState> = createReducer<IDeviceState>(INITIAL_STATE, {
    [DevicesTypes.RESET_LIST]: resetList,
    [DevicesTypes.LOAD_REQUEST]: loadRequest,
    [DevicesTypes.LOAD_SUCCESS]: loadSuccess,
    [DevicesTypes.LOAD_FAILURE]: loadFailure
})

export default reducer
