import { applyMiddleware, createStore, Store } from 'redux'

import createSagaMiddleware from 'redux-saga'
import multi from 'redux-multi'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from './ducks/root.reducer'
import rootSaga from './ducks/root.saga'
import { IAuthState } from './ducks/auth/types'

import { ISnackBarState } from './ducks/snack.bar/types'
import { ILayoutState } from './ducks/layout/types'
import { IUserState } from './ducks/user/types'
import { IRegionalCenterState } from './ducks/regional.centers/types'
import { IReportState } from './ducks/reports/types'
import { ICoordinationState } from './ducks/coordinations/types'
import { IServiceLocationState } from './ducks/service.location/types'
import { IDeviceState } from './ducks/devices/types'

export interface IApplicationState {
    auth: IAuthState,
    snackBar: ISnackBarState,
    layout: ILayoutState,
    user: IUserState,
    regionalCenter: IRegionalCenterState,
    reports: IReportState,
    coordination: ICoordinationState,
    serviceLocation: IServiceLocationState,
    devices: IDeviceState
}

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const store: Store<IApplicationState> = createStore(
    rootReducer(history),
    /* composeWithDevTools is integration for plugin redux/devtools chrome.
    * see https://github.com/zalmoxisus/redux-devtools-extension
    * */
    composeWithDevTools(
        applyMiddleware(multi, sagaMiddleware, routerMiddleware(history))
    )
)

sagaMiddleware.run(rootSaga)

export default store
