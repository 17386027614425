import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { CoordinationTypes } from './types'
import Coordination from '../../application/models/regional.center/coordination'
import ServiceLocation from '../../application/models/regional.center/service.location'

/* Action for reset state */
export const resetCreate = () => action(CoordinationTypes.RESET_CREATE)

export const resetList = () => action(CoordinationTypes.RESET_LIST)

/* Actions for change coordination */
export const changeCoordination = (coordination: Coordination) => action(
    CoordinationTypes.CHANGE_COORDINATION,
    { coordination }
)

export const changePaginator = (paginator?: IPaginator) => [
    action(CoordinationTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    CoordinationTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveDialog = (dialog: boolean, id: string) => action(
    CoordinationTypes.CHANGE_REMOVE_DIALOG,
    { dialog, id }
)

export const changeDialog = (dialog: boolean, coordination?: Coordination) => {
    const pipe: any = []
    if (!dialog) {
        pipe.push(resetCreate())
    }
    if (dialog && coordination?.id) {
        pipe.push(loadLocationsRequest(coordination.id))
    }
    return pipe.concat(action(CoordinationTypes.CHANGE_DIALOG, { dialog, coordination }))

}

export const createRequest = (coordination: Coordination) => action(
    CoordinationTypes.CREATE_REQUEST,
    { coordination }
)

export const createSuccess = (coordination: Coordination) => [
    action(CoordinationTypes.CREATE_SUCCESS, { coordination }),
    resetCreate()
]

export const createFailure = () => action(CoordinationTypes.CREATE_FAILURE)

/* Actions for load coordination */
export const loadRequest = (paginator?: IPaginator) => action(
    CoordinationTypes.LOAD_REQUEST,
    { paginator }
)

export const loadSuccess = (response: IAxiosResponse<Coordination[]>) => action(
    CoordinationTypes.LOAD_SUCCESS, {
        coordinations: response.data,
        headers: response.headers
    })

export const loadFailure = () => action(CoordinationTypes.LOAD_FAILURE)

export const loadMoreRequest = (paginator: IPaginator) => action(
    CoordinationTypes.LOAD_MORE_REQUEST,
    { paginator }
)

export const loadMoreSuccess = (response: IAxiosResponse<Coordination[]>) => action(
    CoordinationTypes.LOAD_MORE_SUCCESS, {
        coordinations: response.data,
        headers: response.headers
    })

export const loadMoreFailure = () => action(CoordinationTypes.LOAD_MORE_FAILURE)


/* Actions for find coordination */
export const findRequest = (id: string) => action(CoordinationTypes.FIND_REQUEST, { id })

export const findSuccess = (coordination: Coordination) => action(
    CoordinationTypes.FIND_SUCCESS,
    { coordination }
)

export const findFailure = () => action(CoordinationTypes.FIND_FAILURE)

/* Actions for update coordination */
export const updateRequest = (coordination: Coordination) => action(
    CoordinationTypes.UPDATE_REQUEST,
    { coordination }
)

export const updateSuccess = (coordination: Coordination) => action(
    CoordinationTypes.UPDATE_SUCCESS,
    { coordination }
)

export const updateFailure = () => action(CoordinationTypes.UPDATE_FAILURE)

/* Actions for remove coordination */
export const removeRequest = (id: string) => action(CoordinationTypes.REMOVE_REQUEST, { id })

export const removeSuccess = (id: string) => action(CoordinationTypes.REMOVE_SUCCESS, { id })

export const removeFailure = () => action(CoordinationTypes.REMOVE_FAILURE)

/* Actions to load service locations for a coordination */
export const loadLocationsRequest = (coordinationId: string, paginator?: IPaginator) => action(
    CoordinationTypes.LOAD_LOCATIONS_REQUEST,
    { coordinationId, paginator }
)

export const loadLocationsSuccess = (response: IAxiosResponse<ServiceLocation[]>) => action(
    CoordinationTypes.LOAD_LOCATIONS_SUCCESS,
    {
        locations: response.data,
        headers: response.headers
    }
)

export const loadLocationsFailure = () => action(CoordinationTypes.LOAD_LOCATIONS_FAILURE)

export const resetLocationsList = () => action(CoordinationTypes.RESET_LOCATIONS_LIST)

export const changeAssociatePaginator = (coordinationId: string, paginator?: IPaginator) => [
    action(CoordinationTypes.CHANGE_ASSOCIATES_PAGINATOR, { paginator }),
    loadLocationsRequest(coordinationId, paginator)
]

export const changeAssociateSearchPaginator = (search: ISearch) => action(
    CoordinationTypes.CHANGE_ASSOCIATES_SEARCH_PAGINATOR,
    { search }
)

/* Actions to control association and dissociation dialogs */
export const changeAssociateDialog = (dialog: boolean) => action(
    CoordinationTypes.CHANGE_ASSOCIATE_DIALOG,
    { dialog }
)

export const changeDissociateDialog = (dialog: boolean, locationId: string) => action(
    CoordinationTypes.CHANGE_DISSOCIATE_DIALOG,
    { dialog, locationId }
)

/* Actions to associate a service location with a coordination */
export const associateRequest = (coordinationId: string, locationId: string) => action(
    CoordinationTypes.ASSOCIATE_LOCATION_REQUEST,
    { coordinationId, locationId }
)

export const associateSuccess = (coordinationId: string, locationId: string) => [
    action(
        CoordinationTypes.ASSOCIATE_LOCATION_SUCCESS,
        { coordinationId, locationId }
    ),
    loadLocationsRequest(coordinationId)
]

export const associateFailure = () => action(CoordinationTypes.ASSOCIATE_LOCATION_FAILURE)

/* Actions to dissociate a service location with a coordination */
export const dissociateRequest = (coordinationId: string, locationId: string) => action(
    CoordinationTypes.DISSOCIATE_LOCATION_REQUEST,
    { coordinationId, locationId }
)

export const dissociateSuccess = (coordinationId: string, locationId: string) => [
    action(
        CoordinationTypes.DISSOCIATE_LOCATION_SUCCESS,
        { coordinationId, locationId }
    ),
    loadLocationsRequest(coordinationId)
]

export const dissociateFailure = () => action(CoordinationTypes.DISSOCIATE_LOCATION_FAILURE)
