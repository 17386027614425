import { all, apply, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import userService from '../../../services/users'
import { IActionType, IAxiosResponse } from '../root.types'
import { open } from '../snack.bar/actions'
import { SnackBarMessageType } from '../../../components/snackbar'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, UserTypes } from './types'

import {
    createFailure,
    createSuccess,
    findFailure,
    findSuccess,
    loadFailure,
    loadMoreFailure,
    loadMoreSuccess,
    loadRequest,
    loadSuccess,
    loadTotalFailure,
    loadTotalSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { changeBreadCrumbLast } from '../layout/actions'
import authService from '../../../services/auth'
import { getUserLogged } from '../auth/sagas'
import { TypesUser } from '../../application/models/users/users'

function* create(action: IActionType<IActionCreate>) {
    try {
        const { user } = action.payload
        const response: any = yield apply(userService, userService.create, [user])
        yield put<any>(createSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.USERS.CREATE.MESSAGE'))
        yield call(reloadUsers, user)
    } catch (e) {
        yield put(createFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { userType, paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(userService,
            userService.getAll, [userType, paginator])
        yield put<any>(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

function* getById(action: IActionType<IActionFind>) {
    yield put(changeBreadCrumbLast(''))
    const { id, userType } = action.payload
    try {
        const response: any = yield apply(userService, userService.getById, [id, userType])
        yield put(findSuccess(response))
        yield put(changeBreadCrumbLast(response?.name))
    } catch (e) {
        yield put(findFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    try {
        const { user } = action.payload
        const response: any = yield apply(userService, userService.update, [user])
        yield put<any>(updateSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.USERS.UPDATE.MESSAGE'))
        yield call(reloadUsers, user)
        const userLoggedId: string = authService.decodeToken()?.sub || ''
        if (userLoggedId === user?.id) {
            yield call(getUserLogged, user.id, user.type)
        }
    } catch (e) {
        yield put(updateFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(userService, userService.remove, [id])
        yield put<any>(removeSuccess(id))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.USERS.REMOVE.MESSAGE'))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* loadMore(action: IActionType<IActionLoad>) {
    try {
        const { userType, paginator } = action.payload
        const response: IAxiosResponse<any[]> = yield apply(
            userService,
            userService.getAll,
            [userType, paginator]
        )
        yield put<any>(loadMoreSuccess(response))
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* reloadUsers(user) {
    if (user?.type) {
        yield put<any>(loadRequest(user?.type))
    }
}

function* getTotalUsers() {
    try {
        const responseCoordinator: IAxiosResponse<any[]>
            = yield apply(userService,
            userService.getAll, [TypesUser.COORDINATOR])
        const totalCoordinators = parseInt(responseCoordinator.headers['x-total-count'], 10)
        const responseSupervisor: IAxiosResponse<any[]>
            = yield apply(userService,
            userService.getAll, [TypesUser.SUPERVISOR])
        const totalSupervisors = parseInt(responseSupervisor.headers['x-total-count'], 10)
        const responseOperators: IAxiosResponse<any[]>
            = yield apply(userService,
            userService.getAll, [TypesUser.OPERATOR])
        const totalOperators = parseInt(responseOperators.headers['x-total-count'], 10)
        const responseElectrician: IAxiosResponse<any[]>
            = yield apply(userService,
            userService.getAll, [TypesUser.ELECTRICIAN])
        const totalElectricians = parseInt(responseElectrician.headers['x-total-count'], 10)
        yield put(loadTotalSuccess(totalCoordinators, totalSupervisors, totalOperators, totalElectricians))
    } catch (e) {
        yield put(loadTotalFailure())
    }
}

export default function* userSaga() {
    return yield all([
        takeLatest(UserTypes.CREATE_REQUEST, create),
        takeLatest(UserTypes.LOAD_REQUEST, getAll),
        takeLatest(UserTypes.FIND_REQUEST, getById),
        takeLatest(UserTypes.UPDATE_REQUEST, update),
        takeLatest(UserTypes.REMOVE_REQUEST, remove),
        takeEvery(UserTypes.LOAD_MORE_REQUEST, loadMore),
        takeEvery(UserTypes.LOAD_TOTAL_REQUEST, getTotalUsers)
    ])
}
