import { Reducer } from 'redux'
import { ILayoutState, LayoutTypes } from './types'
import i18n, { MAP_LANGUAGE_TO_ENUM } from '../../../i18n'

const INITIAL_STATE: ILayoutState = {
    appBar: {
        username: ''
    },
    language: MAP_LANGUAGE_TO_ENUM[i18n.language],
    breadCrumbLast: '',
    expandedNavbar: false
}

const reducer: Reducer<ILayoutState> = (state: ILayoutState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case LayoutTypes.CHANGE_LANGUAGE:
            const { language } = action.payload
            return { ...state, language }

        case LayoutTypes.CHANGE_BREAD_CRUMB:
            const { breadCrumbLast } = action.payload
            return { ...state, breadCrumbLast }

        case LayoutTypes.CHANGE_USERNAME:
            const { username } = action.payload
            return { ...state, appBar: { ...state.appBar, username } }

        case LayoutTypes.CHANGE_EXPAND_NAV_BAR:
            const { expand } = action.payload
            return { ...state, expandedNavbar: expand }

        default:
            return state
    }
}

export default reducer
