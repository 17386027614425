import { lazy } from 'react'
import { SCOPES } from '../store/application/models/auth/scopes'

const ListOfCoordination = lazy(() => import('../containers/coordinations/list'))
const DetailsOfCoordination = lazy(() => import('../containers/coordinations/details'))

export const COORDINATIONS_ROUTES = [
    {
        path: '/app/coordinations',
        component: ListOfCoordination,
        exact: true,
        private: true,
        scopes: [SCOPES.COORDINATION_READ_ALL]
    },
    {
        path: '/app/coordinations/:coordinationId',
        component: DetailsOfCoordination,
        exact: true,
        private: true,
        scopes: [SCOPES.COORDINATION_READ]
    }
]
