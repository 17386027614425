import { action } from 'typesafe-actions'
import { LayoutTypes } from './types'
import { LanguageOptions } from '../../../i18n'
import localStorageService from '../../../services/local.storage'

export const changeLanguage = (language: LanguageOptions) => {
    localStorageService.setItem('language', language)
    return action(LayoutTypes.CHANGE_LANGUAGE, { language })
}

export const changeBreadCrumbLast = (breadCrumbLast: string | undefined) => action(LayoutTypes.CHANGE_BREAD_CRUMB, { breadCrumbLast })

export const changeUsername = (username: string) => {
    localStorageService.setItem('username', username)
    return action(LayoutTypes.CHANGE_USERNAME, { username })
}

export const changeExpandNavbar = (expand: boolean) => action(LayoutTypes.CHANGE_EXPAND_NAV_BAR, { expand })

