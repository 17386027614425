import { JsonUtils } from '../../utils/json.util'

export default class Device {
    private _amount_expired_within_30_days: number | undefined
    private _amount_expired_within_90_days: number | undefined
    private _amount_overdue_more_than_90_days: number | undefined
    private _class_type: string | undefined
    private _connection_date: string | undefined
    private _contract: string | undefined
    private _coordinates: string | undefined
    private _cpf: string | undefined
    private _cut_amount: string | undefined
    private _cut_amount_uc: string | undefined
    private _dec: number | undefined
    private _estimate_code: string | undefined
    private _fec: number | undefined
    private _id: string | undefined
    private _installation: string | undefined
    private _installation_type: string | undefined
    private _invoice_category: string | undefined
    private _kwh_m1: number | undefined
    private _kwh_m2: number | undefined
    private _kwh_m3: number | undefined
    private _kwh_m4: number | undefined
    private _kwh_m5: number | undefined
    private _kwh_m6: number | undefined
    private _kwh_m7: number | undefined
    private _kwh_m8: number | undefined
    private _kwh_m9: number | undefined
    private _kwh_m10: number | undefined
    private _kwh_m11: number | undefined
    private _kwh_m_actual: number | undefined
    private _kwh_toi_invoices: number | undefined
    private _last_cut_date: string | undefined
    private _number_average_invoice: number | undefined
    private _number_complaints: number | undefined
    private _number_complaints_uc: number | undefined
    private _number_minimum_invoice: number | undefined
    private _number_phases: string | undefined
    private _number_reconnections: number | undefined
    private _number_reconnections_uc: number | undefined
    private _number_toi_invoices: number | undefined
    private _open_invoices: number | undefined
    private _readers_note: string | undefined
    private _reading_type: string | undefined
    private _reading_unit: string | undefined
    private _risk_area: string | undefined
    private _situation: string | undefined
    private _status: string | undefined
    private _voltage_group: string | undefined

    get amount_expired_within_30_days(): number | undefined {
        return this._amount_expired_within_30_days
    }

    set amount_expired_within_30_days(value: number | undefined) {
        this._amount_expired_within_30_days = value
    }

    get amount_expired_within_90_days(): number | undefined {
        return this._amount_expired_within_90_days
    }

    set amount_expired_within_90_days(value: number | undefined) {
        this._amount_expired_within_90_days = value
    }

    get amount_overdue_more_than_90_days(): number | undefined {
        return this._amount_overdue_more_than_90_days
    }

    set amount_overdue_more_than_90_days(value: number | undefined) {
        this._amount_overdue_more_than_90_days = value
    }

    get class_type(): string | undefined {
        return this._class_type
    }

    set class_type(value: string | undefined) {
        this._class_type = value
    }

    get connection_date(): string | undefined {
        return this._connection_date
    }

    set connection_date(value: string | undefined) {
        this._connection_date = value
    }

    get contract(): string | undefined {
        return this._contract
    }

    set contract(value: string | undefined) {
        this._contract = value
    }

    get coordinates(): string | undefined {
        return this._coordinates
    }

    set coordinates(value: string | undefined) {
        this._coordinates = value
    }

    get cpf(): string | undefined {
        return this._cpf
    }

    set cpf(value: string | undefined) {
        this._cpf = value
    }

    get cut_amount(): string | undefined {
        return this._cut_amount
    }

    set cut_amount(value: string | undefined) {
        this._cut_amount = value
    }

    get cut_amount_uc(): string | undefined {
        return this._cut_amount_uc
    }

    set cut_amount_uc(value: string | undefined) {
        this._cut_amount_uc = value
    }

    get dec(): number | undefined {
        return this._dec
    }

    set dec(value: number | undefined) {
        this._dec = value
    }

    get estimate_code(): string | undefined {
        return this._estimate_code
    }

    set estimate_code(value: string | undefined) {
        this._estimate_code = value
    }

    get fec(): number | undefined {
        return this._fec
    }

    set fec(value: number | undefined) {
        this._fec = value
    }

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get installation(): string | undefined {
        return this._installation
    }

    set installation(value: string | undefined) {
        this._installation = value
    }

    get installation_type(): string | undefined {
        return this._installation_type
    }

    set installation_type(value: string | undefined) {
        this._installation_type = value
    }

    get invoice_category(): string | undefined {
        return this._invoice_category
    }

    set invoice_category(value: string | undefined) {
        this._invoice_category = value
    }

    get kwh_m1(): number | undefined {
        return this._kwh_m1
    }

    set kwh_m1(value: number | undefined) {
        this._kwh_m1 = value
    }

    get kwh_m2(): number | undefined {
        return this._kwh_m2
    }

    set kwh_m2(value: number | undefined) {
        this._kwh_m2 = value
    }

    get kwh_m3(): number | undefined {
        return this._kwh_m3
    }

    set kwh_m3(value: number | undefined) {
        this._kwh_m3 = value
    }

    get kwh_m4(): number | undefined {
        return this._kwh_m4
    }

    set kwh_m4(value: number | undefined) {
        this._kwh_m4 = value
    }

    get kwh_m5(): number | undefined {
        return this._kwh_m5
    }

    set kwh_m5(value: number | undefined) {
        this._kwh_m5 = value
    }

    get kwh_m6(): number | undefined {
        return this._kwh_m6
    }

    set kwh_m6(value: number | undefined) {
        this._kwh_m6 = value
    }

    get kwh_m7(): number | undefined {
        return this._kwh_m7
    }

    set kwh_m7(value: number | undefined) {
        this._kwh_m7 = value
    }

    get kwh_m8(): number | undefined {
        return this._kwh_m8
    }

    set kwh_m8(value: number | undefined) {
        this._kwh_m8 = value
    }

    get kwh_m9(): number | undefined {
        return this._kwh_m9
    }

    set kwh_m9(value: number | undefined) {
        this._kwh_m9 = value
    }

    get kwh_m10(): number | undefined {
        return this._kwh_m10
    }

    set kwh_m10(value: number | undefined) {
        this._kwh_m10 = value
    }

    get kwh_m11(): number | undefined {
        return this._kwh_m11
    }

    set kwh_m11(value: number | undefined) {
        this._kwh_m11 = value
    }

    get kwh_m_actual(): number | undefined {
        return this._kwh_m_actual
    }

    set kwh_m_actual(value: number | undefined) {
        this._kwh_m_actual = value
    }

    get kwh_toi_invoices(): number | undefined {
        return this._kwh_toi_invoices
    }

    set kwh_toi_invoices(value: number | undefined) {
        this._kwh_toi_invoices = value
    }

    get last_cut_date(): string | undefined {
        return this._last_cut_date
    }

    set last_cut_date(value: string | undefined) {
        this._last_cut_date = value
    }

    get number_average_invoice(): number | undefined {
        return this._number_average_invoice
    }

    set number_average_invoice(value: number | undefined) {
        this._number_average_invoice = value
    }

    get number_complaints(): number | undefined {
        return this._number_complaints
    }

    set number_complaints(value: number | undefined) {
        this._number_complaints = value
    }

    get number_complaints_uc(): number | undefined {
        return this._number_complaints_uc
    }

    set number_complaints_uc(value: number | undefined) {
        this._number_complaints_uc = value
    }

    get number_minimum_invoice(): number | undefined {
        return this._number_minimum_invoice
    }

    set number_minimum_invoice(value: number | undefined) {
        this._number_minimum_invoice = value
    }

    get number_phases(): string | undefined {
        return this._number_phases
    }

    set number_phases(value: string | undefined) {
        this._number_phases = value
    }

    get number_reconnections(): number | undefined {
        return this._number_reconnections
    }

    set number_reconnections(value: number | undefined) {
        this._number_reconnections = value
    }

    get number_reconnections_uc(): number | undefined {
        return this._number_reconnections_uc
    }

    set number_reconnections_uc(value: number | undefined) {
        this._number_reconnections_uc = value
    }

    get number_toi_invoices(): number | undefined {
        return this._number_toi_invoices
    }

    set number_toi_invoices(value: number | undefined) {
        this._number_toi_invoices = value
    }

    get open_invoices(): number | undefined {
        return this._open_invoices
    }

    set open_invoices(value: number | undefined) {
        this._open_invoices = value
    }

    get readers_note(): string | undefined {
        return this._readers_note
    }

    set readers_note(value: string | undefined) {
        this._readers_note = value
    }

    get reading_type(): string | undefined {
        return this._reading_type
    }

    set reading_type(value: string | undefined) {
        this._reading_type = value
    }

    get reading_unit(): string | undefined {
        return this._reading_unit
    }

    set reading_unit(value: string | undefined) {
        this._reading_unit = value
    }

    get risk_area(): string | undefined {
        return this._risk_area
    }

    set risk_area(value: string | undefined) {
        this._risk_area = value
    }

    get situation(): string | undefined {
        return this._situation
    }

    set situation(value: string | undefined) {
        this._situation = value
    }

    get status(): string | undefined {
        return this._status
    }

    set status(value: string | undefined) {
        this._status = value
    }

    get voltage_group(): string | undefined {
        return this._voltage_group
    }

    set voltage_group(value: string | undefined) {
        this._voltage_group = value
    }

    public fromJSON(json: any): Device {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.amount_expired_within_30_days !== undefined) {
            this.amount_expired_within_30_days = json.amount_expired_within_30_days
        }

        if (json.amount_expired_within_90_days !== undefined) {
            this.amount_expired_within_90_days = json.amount_expired_within_90_days
        }

        if (json.amount_overdue_more_than_90_days !== undefined) {
            this.amount_overdue_more_than_90_days = json.amount_overdue_more_than_90_days
        }

        if (json.class_type !== undefined) {
            this.class_type = json.class_type
        }

        if (json.connection_date !== undefined) {
            this.connection_date = json.connection_date
        }

        if (json.contract !== undefined) {
            this.contract = json.contract
        }

        if (json.coordinates !== undefined) {
            this.coordinates = `${json.coordinates}`.replace(/,/g,'.')
        }

        if (json.cpf !== undefined) {
            this.cpf = json.cpf
        }

        if (json.cut_amount !== undefined) {
            this.cut_amount = json.cut_amount
        }

        if (json.cut_amount_uc !== undefined) {
            this.cut_amount_uc = json.cut_amount_uc
        }

        if (json.dec !== undefined) {
            this.dec = json.dec
        }

        if (json.estimate_code !== undefined) {
            this.estimate_code = json.estimate_code
        }

        if (json.fec !== undefined) {
            this.fec = json.fec
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.installation !== undefined) {
            this.installation = json.installation
        }

        if (json.installation_type !== undefined) {
            this.installation_type = json.installation_type
        }

        if (json.invoice_category !== undefined) {
            this.invoice_category = json.invoice_category
        }

        if (json.kwh_m1 !== undefined) {
            this.kwh_m1 = json.kwh_m1
        }

        if (json.kwh_m2 !== undefined) {
            this.kwh_m2 = json.kwh_m2
        }

        if (json.kwh_m3 !== undefined) {
            this.kwh_m3 = json.kwh_m3
        }

        if (json.kwh_m4 !== undefined) {
            this.kwh_m4 = json.kwh_m4
        }

        if (json.kwh_m5 !== undefined) {
            this.kwh_m5 = json.kwh_m5
        }

        if (json.kwh_m6 !== undefined) {
            this.kwh_m6 = json.kwh_m6
        }

        if (json.kwh_m7 !== undefined) {
            this.kwh_m7 = json.kwh_m7
        }

        if (json.kwh_m8 !== undefined) {
            this.kwh_m8 = json.kwh_m8
        }

        if (json.kwh_m9 !== undefined) {
            this.kwh_m9 = json.kwh_m9
        }

        if (json.kwh_m10 !== undefined) {
            this.kwh_m10 = json.kwh_m10
        }

        if (json.kwh_m11 !== undefined) {
            this.kwh_m11 = json.kwh_m11
        }

        if (json.kwh_m_actual !== undefined) {
            this.kwh_m_actual = json.kwh_m_actual
        }

        if (json.kwh_toi_invoices !== undefined) {
            this.kwh_toi_invoices = json.kwh_toi_invoices
        }

        if (json.last_cut_date !== undefined) {
            this.last_cut_date = json.last_cut_date
        }

        if (json.number_average_invoice !== undefined) {
            this.number_average_invoice = json.number_average_invoice
        }

        if (json.number_complaints !== undefined) {
            this.number_complaints = json.number_complaints
        }

        if (json.number_complaints_uc !== undefined) {
            this.number_complaints_uc = json.number_complaints_uc
        }

        if (json.number_minimum_invoice !== undefined) {
            this.number_minimum_invoice = json.number_minimum_invoice
        }

        if (json.number_phases !== undefined) {
            this.number_phases = json.number_phases
        }

        if (json.number_reconnections !== undefined) {
            this.number_reconnections = json.number_reconnections
        }

        if (json.number_reconnections_uc !== undefined) {
            this.number_reconnections_uc = json.number_reconnections_uc
        }

        if (json.number_toi_invoices !== undefined) {
            this.number_toi_invoices = json.number_toi_invoices
        }

        if (json.open_invoices !== undefined) {
            this.open_invoices = json.open_invoices
        }

        if (json.readers_note !== undefined) {
            this.readers_note = json.readers_note
        }

        if (json.reading_type !== undefined) {
            this.reading_type = json.reading_type
        }

        if (json.reading_unit !== undefined) {
            this.reading_unit = json.reading_unit
        }

        if (json.risk_area !== undefined) {
            this.risk_area = json.risk_area
        }

        if (json.situation !== undefined) {
            this.situation = json.situation
        }

        if (json.status !== undefined) {
            this.status = json.status
        }

        if (json.voltage_group !== undefined) {
            this.voltage_group = json.voltage_group
        }

        return this
    }

    public toJSON(): any {
        return {
            amount_expired_within_30_days: this.amount_expired_within_30_days || undefined,
            amount_expired_within_90_days: this.amount_expired_within_90_days || undefined,
            amount_overdue_more_than_90_days: this.amount_overdue_more_than_90_days || undefined,
            class_type: this.class_type || undefined,
            connection_date: this.connection_date || undefined,
            contract: this.contract || undefined,
            coordinates: this.coordinates || undefined,
            cpf: this.cpf || undefined,
            cut_amount: this.cut_amount || undefined,
            cut_amount_uc: this.cut_amount_uc || undefined,
            dec: this.dec || undefined,
            estimate_code: this.estimate_code || undefined,
            fec: this.fec || undefined,
            id: this.id || undefined,
            installation: this.installation || undefined,
            installation_type: this.installation_type || undefined,
            invoice_category: this.invoice_category || undefined,
            kwh_m1: this.kwh_m1 || undefined,
            kwh_m2: this.kwh_m2 || undefined,
            kwh_m3: this.kwh_m3 || undefined,
            kwh_m4: this.kwh_m4 || undefined,
            kwh_m5: this.kwh_m5 || undefined,
            kwh_m6: this.kwh_m6 || undefined,
            kwh_m7: this.kwh_m7 || undefined,
            kwh_m8: this.kwh_m8 || undefined,
            kwh_m9: this.kwh_m9 || undefined,
            kwh_m10: this.kwh_m10 || undefined,
            kwh_m11: this.kwh_m11 || undefined,
            kwh_m_actual: this.kwh_m_actual || undefined,
            kwh_toi_invoices: this.kwh_toi_invoices || undefined,
            last_cut_date: this.last_cut_date || undefined,
            number_average_invoice: this.number_average_invoice || undefined,
            number_complaints: this.number_complaints || undefined,
            number_complaints_uc: this.number_complaints_uc || undefined,
            number_minimum_invoice: this.number_minimum_invoice || undefined,
            number_phases: this.number_phases || undefined,
            number_reconnections: this.number_reconnections || undefined,
            number_reconnections_uc: this.number_reconnections_uc || undefined,
            number_toi_invoices: this.number_toi_invoices || undefined,
            open_invoices: this.open_invoices || undefined,
            readers_note: this.readers_note || undefined,
            reading_type: this.reading_type || undefined,
            reading_unit: this.reading_unit || undefined,
            risk_area: this.risk_area || undefined,
            situation: this.situation || undefined,
            status: this.status || undefined,
            voltage_group: this.voltage_group || undefined
        }
    }
}
