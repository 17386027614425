import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator } from '../root.types'
import { ReportTypes } from './types'
import Call from '../../application/models/call/call'

/* Actions for load Regional Centers */
export const loadRequest = (paginator?: IPaginator) => action(ReportTypes.LOAD_REQUEST, { paginator })

export const loadSuccess = (response: IAxiosResponse<Call[]>) => action(ReportTypes.LOAD_SUCCESS, {
    calls: response.data,
    headers: response.headers
})

export const loadFailure = () => action(ReportTypes.LOAD_FAILURE)
