import { all, apply, put, takeLatest } from 'redux-saga/effects'
import { IActionType, IAxiosResponse } from '../root.types'
import { IActionLoad, DevicesTypes } from './types'
import { loadFailure, loadSuccess } from './actions'
import deviceService from '../../../services/device'

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { installations } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(
            deviceService,
            deviceService.getByInstallations,
            [installations]
        )
        yield put<any>(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

export default function* DevicesSaga() {
    return yield all([
        takeLatest(DevicesTypes.LOAD_REQUEST, getAll)
    ])
}
