import User, { MAP_TYPE_USER_TO_ENDPOINT, TypesUser } from '../store/application/models/users/users'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import axiosInstance from './axios'
import { AxiosResponse } from 'axios'

class UserService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(user: User): Promise<User> {
        const url: string = `${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[user?.type || '']}`
        return axiosInstance
            .post(url, user.toJSON())
            .then(response => new User().fromJSON({
                ...response.data,
                type: user.type
            }))
    }

    public getAll(userType: TypesUser, paginator?: IPaginator): Promise<IAxiosResponse<User[]>> {
        const params: URLSearchParams = this.buildParams(paginator)
        return axiosInstance
            .get(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(user => new User().fromJSON({ ...user, type: userType })),
                    headers: response.headers
                }
            })
    }

    public getById(userId: string, userType: TypesUser): Promise<User> {
        return axiosInstance.get(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}`)
            .then(response => new User().fromJSON({
                ...response?.data,
                type: userType
            }))
    }

    public buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (paginator?.search?.key && paginator?.search?.value)
                || (paginator?.search?.key === 'email' && paginator?.search?.value)
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }
        return params
    }

    public update(user: User): Promise<User> {
        user.password = undefined
        user.email = undefined
        user.light_matriculation = undefined
        const userType = user?.type || ''
        return axiosInstance
            .patch(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${user.id}`, user.toJSON())
            .then(response => new User().fromJSON({
                ...response.data,
                type: userType
            }))
    }

    public remove(userId: string): Promise<void> {
        return axiosInstance.delete(`${this.apiVersion}/users/${userId}`)
    }

}

export default new UserService()
