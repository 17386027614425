import React, { Component } from 'react'
import { CircularProgress, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'

interface Props extends WithStyles<typeof Style> {
    readonly message?: string
}

const Style = (theme: Theme) => createStyles({
    external: {
        position: 'fixed',
        zIndex: 2000,
        background: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0
    },
    internal: {
        position: 'absolute',
        background: theme.palette.white.main,
        borderRadius: '5px',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

class Loading extends Component <Props> {

    public render() {
        const { message, classes } = this.props
        return (
            <div className={classes.external}>

                <div className={classes.internal}>

                    <CircularProgress/>

                    {
                        message && <p className="text-center">{message}</p>
                    }

                </div>

            </div>
        )
    }
}

export default withStyles<any>(Style, { withTheme: true })(Loading)
