import { lazy } from 'react'
import { TypesUser } from '../store/application/models/users/users'
import { SCOPES } from '../store/application/models/auth/scopes'

const ListOfCoordinators = lazy(() => import('../containers/users/coordinators/list'))
const ProfileCoordinator = lazy(() => import('../containers/users/coordinators/profile'))

const ListOfSupervisors = lazy(() => import('../containers/users/supervisors/list'))
const ProfileSupervisor = lazy(() => import('../containers/users/supervisors/profile'))

const ListOfOperators = lazy(() => import('../containers/users/operators/list'))
const ProfileOperator = lazy(() => import('../containers/users/operators/profile'))

const ListOfElectricians = lazy(() => import('../containers/users/electrician/list'))
const ProfileElectrician = lazy(() => import('../containers/users/electrician/profile'))

const UserProfileByType = lazy(() => import('../containers/users/profile.by.user.type'))

export const USERS_ROUTES = [
    {
        path: '/app/myprofile',
        component: UserProfileByType,
        exact: true,
        private: true,
        userTypes: [
            TypesUser.COORDINATOR,
            TypesUser.SUPERVISOR,
            TypesUser.OPERATOR,
            TypesUser.ELECTRICIAN
        ]
    },
    {
        path: '/app/coordinators',
        component: ListOfCoordinators,
        exact: true,
        private: true,
        scopes: [SCOPES.COORDINATOR_READ_ALL],
        userTypes: [TypesUser.ADMINISTRATOR]
    },
    {
        path: '/app/coordinators/:userId',
        component: ProfileCoordinator,
        exact: true,
        private: true,
        scopes: [SCOPES.COORDINATOR_READ],
        userTypes: [TypesUser.ADMINISTRATOR]
    },
    {
        path: '/app/supervisors',
        component: ListOfSupervisors,
        exact: true,
        private: true,
        scopes: [SCOPES.SUPERVISOR_READ_ALL],
        userTypes: [TypesUser.ADMINISTRATOR]
    },
    {
        path: '/app/supervisors/:userId',
        component: ProfileSupervisor,
        exact: true,
        private: true,
        scopes: [SCOPES.SUPERVISOR_READ]
    },
    {
        path: '/app/operators',
        component: ListOfOperators,
        exact: true,
        private: true,
        scopes: [SCOPES.OPERATOR_READ_ALL],
        userTypes: [TypesUser.ADMINISTRATOR]
    },
    {
        path: '/app/operators/:userId',
        component: ProfileOperator,
        exact: true,
        private: true,
        scopes: [SCOPES.OPERATOR_READ],
        userTypes: [TypesUser.ADMINISTRATOR]
    },
    {
        path: '/app/electricians',
        component: ListOfElectricians,
        exact: true,
        private: true,
        scopes: [SCOPES.ELECTRICIAN_READ_ALL],
        userTypes: [TypesUser.ADMINISTRATOR]
    },
    {
        path: '/app/electricians/:userId',
        component: ProfileElectrician,
        exact: true,
        private: true,
        scopes: [SCOPES.ELECTRICIAN_READ],
        userTypes: [TypesUser.ADMINISTRATOR]
    }
]
