import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Call from '../store/application/models/call/call'

const CALLS: Call[] = [
    {
        "region": "PE",
        "service_number": "58871612",
        "priority": "C03",
        "substation": "CLG",
        "dh_complains": "2018-01-01 06:58",
        "dh_scheduled": "2018-01-02 09:09:51",
        "dh_designates": "2018-01-01 07:31:21",
        "dh_activate": "2018-01-01 08:45:00",
        "dh_location": "2018-01-01 08:55:01",
        "dh_terreal": "2018-01-01 09:09:51",
        "service_type": "2016-08-29T09:12:33.001Z",
        "address": "AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO",
        "affected_device": "400020885",
        "proceed": "96%",
        "consumer_registration": 412967288,
        "consumer_name": "FELIPE REZENDE DE FARIA",
        "cpf_cnpj": "74322969787",
        "phone": "33951896",
        "recidivism": 0,
        "last_opr": "4007326",
        "first_opr": "4007328"
    },
    {
        "region": "PE",
        "service_number": "58871612",
        "priority": "C03",
        "substation": "CLG",
        "dh_complains": "2018-01-01 06:58",
        "dh_scheduled": "2018-01-02 09:09:51",
        "dh_designates": "2018-01-01 07:31:21",
        "dh_activate": "2018-01-01 08:45:00",
        "dh_location": "2018-01-01 08:55:01",
        "dh_terreal": "2018-01-01 09:09:51",
        "service_type": "2016-08-29T09:12:33.001Z",
        "address": "AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO",
        "affected_device": "400020885",
        "proceed": "96%",
        "consumer_registration": 412967288,
        "consumer_name": "FELIPE REZENDE DE FARIA",
        "cpf_cnpj": "74322969787",
        "phone": "33951896",
        "recidivism": 0,
        "last_opr": "4007326",
        "first_opr": "4007328"
    },
    {
        "region": "PE",
        "service_number": "58871612",
        "priority": "C03",
        "substation": "CLG",
        "dh_complains": "2018-01-01 06:58",
        "dh_scheduled": "2018-01-02 09:09:51",
        "dh_designates": "2018-01-01 07:31:21",
        "dh_activate": "2018-01-01 08:45:00",
        "dh_location": "2018-01-01 08:55:01",
        "dh_terreal": "2018-01-01 09:09:51",
        "service_type": "2016-08-29T09:12:33.001Z",
        "address": "AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO",
        "affected_device": "400020885",
        "proceed": "96%",
        "consumer_registration": 412967288,
        "consumer_name": "FELIPE REZENDE DE FARIA",
        "cpf_cnpj": "74322969787",
        "phone": "33951896",
        "recidivism": 0,
        "last_opr": "4007326",
        "first_opr": "4007328"
    },
    {
        "region": "PE",
        "service_number": "58871612",
        "priority": "C03",
        "substation": "CLG",
        "dh_complains": "2018-01-01 06:58",
        "dh_scheduled": "2018-01-02 09:09:51",
        "dh_designates": "2018-01-01 07:31:21",
        "dh_activate": "2018-01-01 08:45:00",
        "dh_location": "2018-01-01 08:55:01",
        "dh_terreal": "2018-01-01 09:09:51",
        "service_type": "2016-08-29T09:12:33.001Z",
        "address": "AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO",
        "affected_device": "400020885",
        "proceed": "96%",
        "consumer_registration": 412967288,
        "consumer_name": "FELIPE REZENDE DE FARIA",
        "cpf_cnpj": "74322969787",
        "phone": "33951896",
        "recidivism": 0,
        "last_opr": "4007326",
        "first_opr": "4007328"
    },
    {
        "region": "PE",
        "service_number": "58871612",
        "priority": "C03",
        "substation": "CLG",
        "dh_complains": "2018-01-01 06:58",
        "dh_scheduled": "2018-01-02 09:09:51",
        "dh_designates": "2018-01-01 07:31:21",
        "dh_activate": "2018-01-01 08:45:00",
        "dh_location": "2018-01-01 08:55:01",
        "dh_terreal": "2018-01-01 09:09:51",
        "service_type": "2016-08-29T09:12:33.001Z",
        "address": "AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO",
        "affected_device": "400020885",
        "proceed": "96%",
        "consumer_registration": 412967288,
        "consumer_name": "FELIPE REZENDE DE FARIA",
        "cpf_cnpj": "74322969787",
        "phone": "33951896",
        "recidivism": 0,
        "last_opr": "4007326",
        "first_opr": "4007328"
    },
    {
        "region": "PE",
        "service_number": "58871612",
        "priority": "C03",
        "substation": "CLG",
        "dh_complains": "2018-01-01 06:58",
        "dh_scheduled": "2018-01-02 09:09:51",
        "dh_designates": "2018-01-01 07:31:21",
        "dh_activate": "2018-01-01 08:45:00",
        "dh_location": "2018-01-01 08:55:01",
        "dh_terreal": "2018-01-01 09:09:51",
        "service_type": "2016-08-29T09:12:33.001Z",
        "address": "AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO",
        "affected_device": "400020885",
        "proceed": "96%",
        "consumer_registration": 412967288,
        "consumer_name": "FELIPE REZENDE DE FARIA",
        "cpf_cnpj": "74322969787",
        "phone": "33951896",
        "recidivism": 0,
        "last_opr": "4007326",
        "first_opr": "4007328"
    },
    {
        "region": "PE",
        "service_number": "58871612",
        "priority": "C03",
        "substation": "CLG",
        "dh_complains": "2018-01-01 06:58",
        "dh_scheduled": "2018-01-02 09:09:51",
        "dh_designates": "2018-01-01 07:31:21",
        "dh_activate": "2018-01-01 08:45:00",
        "dh_location": "2018-01-01 08:55:01",
        "dh_terreal": "2018-01-01 09:09:51",
        "service_type": "2016-08-29T09:12:33.001Z",
        "address": "AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO",
        "affected_device": "400020885",
        "proceed": "96%",
        "consumer_registration": 412967288,
        "consumer_name": "FELIPE REZENDE DE FARIA",
        "cpf_cnpj": "74322969787",
        "phone": "33951896",
        "recidivism": 0,
        "last_opr": "4007326",
        "first_opr": "4007328"
    }
].map((item: any) => new Call().fromJSON(item))

class CallService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getAll(paginator?: IPaginator): Promise<IAxiosResponse<Call[]>> {
        return new Promise((resolve) => {
            setTimeout(() => resolve({ data: CALLS, headers: { 'x-total-count': CALLS.length } }), 3000)
        })
        // const params: URLSearchParams = this.buildParams(paginator)
        // return axiosInstance
        //     .get(`${this.apiVersion}/regional_centers`, { params })
        //     .then((response: AxiosResponse<any[]>) => {
        //         return {
        //             data: response.data.map(user => new RegionalCenter().fromJSON({ ...user, type: userType })),
        //             headers: response.headers
        //         }
        //     })
    }

}

export default new CallService()
