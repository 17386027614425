import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../root.types'
import ServiceLocation from '../../application/models/regional.center/service.location'

export const ServiceLocationTypes = createTypes(`
    RESET_CREATE 
    RESET_LIST

    CHANGE_SERVICE_LOCATION 
    CHANGE_PAGINATOR
    CHANGE_SEARCH_PAGINATOR
    CHANGE_REMOVE_DIALOG
    CHANGE_DIALOG 
   
    CREATE_REQUEST
    CREATE_SUCCESS
    CREATE_FAILURE

    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE

    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE

    UPDATE_REQUEST
    UPDATE_SUCCESS
    UPDATE_FAILURE

    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE
`,
    {
        prefix: '@service.location/'
    }
)

/**
 * Action types
 */
export interface IActionCreate {
    readonly location: ServiceLocation
}

export interface IActionFind {
    readonly id: string
}

export interface IActionLoad {
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly id: string
}

/**
 * State types
 */
interface ICreateState extends IComponentState {
    readonly location: ServiceLocation
    readonly dialog: boolean
}

export interface IListState extends IComponentState {
    readonly locations: ServiceLocation[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly id: string
}


export interface IServiceLocationState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
