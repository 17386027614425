import { all, fork } from 'redux-saga/effects'

import authSaga from './auth/sagas'
import userSaga from './user/sagas'
import regionalCenterSaga from './regional.centers/sagas'
import reportSaga from './reports/sagas'
import coordinationSaga from './coordinations/sagas'
import serviceLocationSaga from './service.location/sagas'
import deviceSaga from './devices/sagas'

export default function* rootSaga() {
    return yield all([
        fork(authSaga),
        fork(userSaga),
        fork(regionalCenterSaga),
        fork(reportSaga),
        fork(coordinationSaga),
        fork(serviceLocationSaga),
        fork(deviceSaga)
    ])
}
