const CONFIG_THEME = {
    spacing: 10,
    palette: {
        primary: { main: '#00A29B' },
        secondary: { main: '#165C73' },
        tertiary: { main: '#FFC334' },
        accent: { main: '#AAD05E' },

        error: { main: '#A94442' },
        warning: { main: '#FF7043' },
        info: { main: '#165C73' },
        success: { main: '#22BB33' },

        white: { main: '#FFFFFF' },
        black: { main: '#000000' },
        green: { main: '#4CB050' },
        blue: { main: '#0000ff' },

        backgroundColor: { main: '#F0F0F0' },
        backgroundCard: { main: '#FFFFFF' },
        lineDivider: { main: '#E8E8E8' },

        text: {
            primary: '#666666'
            // secondary: '#CBEBE0',
            // disabled: 'rgba(0,0,0,0.38)'
        },

        clientA: { main: '#C70039' },
        clientB: { main: '#FF5733' },
        clientC: { main: '#C58917' },
        clientD: { main: '#ffc846' },
        riskDeath: { main: '#ff0000' },
        bigEvent: { main: '#512E5F' },
        guaranteedSupply: { main: '#22BB33' },
        lifeSupport: { main: '#ff0000' },
        powerPublic: { main: '#00468c' },
        priority: { main: '#FFC334' },
    },
    typography: {
        fontFamily: 'Roboto'
    }
}

export const ANIMATION = {
    "@keyframes fadeIn": {
        "from": {
            opacity: '0',
            transform: 'translate(0, -15px)'
        },
        "to": {
            opacity: '1',
            transform: 'translate(0, 0)'
        }
    },
    "@keyframes fadeInContent": {
        "from": {
            opacity: '0'
        },
        "to": {
            opacity: '1'
        }
    },
    fadeIn1: {
        animation: `$fadeIn 1s`
    },
    fadeIn2: {
        animation: `$fadeIn 2s`
    },
    fadeIn3: {
        animation: `$fadeIn 3s`
    },
    fadeInContent: {
        animation: `$fadeInContent 1.7s`
    }
}

export default CONFIG_THEME
