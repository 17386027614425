import { lazy } from 'react'
import { SCOPES } from '../store/application/models/auth/scopes'

const ListOfLocations = lazy(() => import('../containers/service.locations/list'))
const DetailsOfLocation = lazy(() => import('../containers/service.locations/details'))

export const SERVICE_LOCATIONS_ROUTES = [
    {
        path: '/app/service_locations',
        component: ListOfLocations,
        exact: true,
        private: true,
        scopes: [SCOPES.SERVICE_LOCATION_READ_ALL]
    },
    {
        path: '/app/service_locations/:locationId',
        component: DetailsOfLocation,
        exact: true,
        private: true,
        scopes: [SCOPES.SERVICE_LOCATION_READ]
    }
]
