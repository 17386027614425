import { JsonUtils } from '../../utils/json.util'
import Indicator from './indicator'

export default class Iri {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _value: number | undefined
    private _regionalcenter_id: string | undefined
    private _user_id: string | undefined
    private _user_name: string | undefined
    private _light_matriculation: string | undefined
    private _month: string | undefined
    private _year: string | undefined
    private _indicators: Indicator | undefined


    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get regionalcenter_id(): string | undefined {
        return this._regionalcenter_id
    }

    set regionalcenter_id(value: string | undefined) {
        this._regionalcenter_id = value
    }

    get user_id(): string | undefined {
        return this._user_id
    }

    set user_id(value: string | undefined) {
        this._user_id = value
    }

    get user_name(): string | undefined {
        return this._user_name
    }

    set user_name(value: string | undefined) {
        this._user_name = value
    }

    get light_matriculation(): string | undefined {
        return this._light_matriculation
    }

    set light_matriculation(value: string | undefined) {
        this._light_matriculation = value
    }

    get month(): string | undefined {
        return this._month
    }

    set month(value: string | undefined) {
        this._month = value
    }

    get year(): string | undefined {
        return this._year
    }

    set year(value: string | undefined) {
        this._year = value
    }

    get indicators(): Indicator | undefined {
        return this._indicators
    }

    set indicators(value: Indicator | undefined) {
        this._indicators = value
    }

    public fromJSON(json: any): Iri {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.value !== undefined) {
            this.value = json.value
        }

        if (json.regionalcenter_id !== undefined) {
            this.regionalcenter_id = json.regionalcenter_id
        }

        if (json.user_id !== undefined) {
            this.user_id = json.user_id
        }

        if (json.user_name !== undefined) {
            this.user_name = json.user_name
        }

        if (json.light_matriculation !== undefined) {
            this.light_matriculation = json.light_matriculation
        }

        if (json.month !== undefined) {
            this.month = json.month
        }

        if (json.year !== undefined) {
            this.year = json.year
        }

        if (json.indicators !== undefined) {
            this.indicators = new Indicator().fromJSON(json.indicators)
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id || undefined,
            created_at: this.created_at || undefined,
            updated_at: this.updated_at || undefined,
            value: this.value || undefined,
            regionalcenter_id: this.regionalcenter_id || undefined,
            user_id: this.user_id || undefined,
            user_name: this.user_name || undefined,
            light_matriculation: this.light_matriculation || undefined,
            month: this.month || undefined,
            year: this.year || undefined,
            indicators: this.indicators?.toJSON() || undefined
        }
    }

    public percentValue(): number {
        return parseFloat(Number(((this.value || 0) * 100) / 12).toFixed(2))
    }
}
