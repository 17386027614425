import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../root.types'
import Coordination from '../../application/models/regional.center/coordination'
import ServiceLocation from '../../application/models/regional.center/service.location'

export const CoordinationTypes = createTypes(`
    RESET_CREATE 
    RESET_LIST

    CHANGE_COORDINATION 
    CHANGE_PAGINATOR
    CHANGE_SEARCH_PAGINATOR
    CHANGE_REMOVE_DIALOG
    CHANGE_DIALOG 
   
    CREATE_REQUEST
    CREATE_SUCCESS
    CREATE_FAILURE

    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE

    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE

    UPDATE_REQUEST
    UPDATE_SUCCESS
    UPDATE_FAILURE

    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE
    
    LOAD_LOCATIONS_REQUEST
    LOAD_LOCATIONS_SUCCESS
    LOAD_LOCATIONS_FAILURE
    
    RESET_LOCATIONS_LIST
    
    CHANGE_ASSOCIATES_PAGINATOR
    CHANGE_ASSOCIATES_SEARCH_PAGINATOR
    
    CHANGE_ASSOCIATE_DIALOG
    CHANGE_DISSOCIATE_DIALOG
    
    ASSOCIATE_LOCATION_REQUEST
    ASSOCIATE_LOCATION_SUCCESS
    ASSOCIATE_LOCATION_FAILURE
    
    DISSOCIATE_LOCATION_REQUEST
    DISSOCIATE_LOCATION_SUCCESS
    DISSOCIATE_LOCATION_FAILURE
`,
    {
        prefix: '@coordinations/'
    }
)

/**
 * Action types
 */
export interface IActionCreate {
    readonly coordination: Coordination
}

export interface IActionFind {
    readonly id: string
}

export interface IActionLoad {
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly id: string
}

export interface IActionLoadAssociate {
    readonly coordinationId: string
    readonly paginator?: IPaginator
}
export interface IActionAssociate {
    readonly coordinationId: string
    readonly locationId: string
}

/**
 * State types
 */
interface ICreateState extends IComponentState {
    readonly coordination: Coordination
    readonly dialog: boolean
}

export interface IListState extends IComponentState {
    readonly coordinations: Coordination[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly id: string
}

export interface ILocationsState extends IComponentState {
    readonly locations: ServiceLocation[]
    readonly paginator: IPaginator
    readonly associating: boolean
    readonly dialogAssociate: boolean
    readonly dissociating: boolean
    readonly dialogDissociate: boolean
    readonly dissociateId?: string
}

export interface ICoordinationState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly selected: Coordination
    readonly serviceLocation: ILocationsState
}
