import { JsonUtils } from '../../utils/json.util'

export enum TypesUser {
    ADMINISTRATOR = 'admin',
    COORDINATOR = 'coordinator',
    SUPERVISOR = 'supervisor',
    OPERATOR = 'operator',
    ELECTRICIAN = 'electrician'
}

export const MAP_TYPE_USER_TO_ENDPOINT = {
    [TypesUser.ADMINISTRATOR]: 'admins',
    [TypesUser.COORDINATOR]: 'coordinators',
    [TypesUser.SUPERVISOR]: 'supervisors',
    [TypesUser.OPERATOR]: 'operators',
    [TypesUser.ELECTRICIAN]: 'electricians',
}

export default class User {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _name: string | undefined
    private _email: string | undefined
    private _last_login: string | undefined
    private _password: string | undefined
    private _type: TypesUser | undefined
    private _light_matriculation: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get email(): string | undefined {
        return this._email
    }

    set email(value: string | undefined) {
        this._email = value
    }

    get last_login(): string | undefined {
        return this._last_login
    }

    set last_login(value: string | undefined) {
        this._last_login = value
    }

    get password(): string | undefined {
        return this._password
    }

    set password(value: string | undefined) {
        this._password = value
    }

    get type(): TypesUser | undefined {
        return this._type
    }

    set type(value: TypesUser | undefined) {
        this._type = value
    }

    get light_matriculation(): string | undefined {
        return this._light_matriculation
    }

    set light_matriculation(value: string | undefined) {
        this._light_matriculation = value
    }

    public fromJSON(json: any): User {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.email !== undefined) {
            this.email = json.email
        }

        if (json.last_login !== undefined) {
            this.last_login = json.last_login
        }

        if (json.password !== undefined) {
            this.password = json.password
        }

        if (json.type !== undefined) {
            this.type = json.type
        }

        if (json.light_matriculation !== undefined) {
            this.light_matriculation = json.light_matriculation
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            name: this.name ? this.name : undefined,
            email: this.email ? this.email : undefined,
            last_login: this.last_login ? this.last_login : undefined,
            password: this.password ? this.password : undefined,
            type: this.type ? this.type : undefined,
            light_matriculation: this.light_matriculation ? this.light_matriculation : undefined
        }
    }
}
