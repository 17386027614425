import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../root.types'
import RegionalCenter from '../../application/models/regional.center/regional.center'
import { IListState as IListUserState } from '../user/types'
import User, { TypesUser } from '../../application/models/users/users'
import Coordination from '../../application/models/regional.center/coordination'
import ServiceLocation from '../../application/models/regional.center/service.location'
import Call from '../../application/models/call/call'
import Indicator from '../../application/models/regional.center/indicator'
import Iri from '../../application/models/regional.center/iri'

export const CenterTypes = createTypes(`
    RESET_CREATE 
    RESET_LIST

    CHANGE_CENTER 
    CHANGE_PAGINATOR
    CHANGE_SEARCH_PAGINATOR
    CHANGE_REMOVE_DIALOG
    CHANGE_DIALOG 
   
    CREATE_REQUEST
    CREATE_SUCCESS
    CREATE_FAILURE

    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE

    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE

    UPDATE_REQUEST
    UPDATE_SUCCESS
    UPDATE_FAILURE

    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE
    
    LOAD_ASSOCIATES_REQUEST
    LOAD_ASSOCIATES_SUCCESS
    LOAD_ASSOCIATES_FAILURE
    
    LOAD_MORE_ASSOCIATES_REQUEST
    LOAD_MORE_ASSOCIATES_SUCCESS
    LOAD_MORE_ASSOCIATES_FAILURE
    
    CHANGE_ASSOCIATE_DIALOG
    
    CHANGE_ASSOCIATES_PAGINATOR
    CHANGE_ASSOCIATES_SEARCH_PAGINATOR
    
    ASSOCIATE_USER_REQUEST
    ASSOCIATE_USER_SUCCESS
    ASSOCIATE_USER_FAILURE
    
    DISSOCIATE_USER_REQUEST
    DISSOCIATE_USER_SUCCESS
    DISSOCIATE_USER_FAILURE
    
    CHANGE_DISSOCIATE_DIALOG
    
    LOAD_CENTERS_BY_USER_REQUEST
    LOAD_CENTERS_BY_USER_SUCCESS
    LOAD_CENTERS_BY_USER_FAILURE   
    
    CHANGE_REGIONAL_SELECTED
    
    LOAD_COORDINATIONS_REQUEST
    LOAD_COORDINATIONS_SUCCESS
    LOAD_COORDINATIONS_FAILURE
    RESET_COORDINATIONS_LIST
    
    LOAD_LOCATIONS_REQUEST
    LOAD_LOCATIONS_SUCCESS
    LOAD_LOCATIONS_FAILURE   
    
    CHANGE_COORDINATIONS_PAGINATOR
    CHANGE_COORDINATIONS_SEARCH_PAGINATOR
    
    CHANGE_COORDINATIONS_DIALOG_ASSOCIATE
    
    ASSOCIATE_COORDINATIONS_REQUEST
    ASSOCIATE_COORDINATIONS_SUCCESS
    ASSOCIATE_COORDINATIONS_FAILURE
    
    DISSOCIATE_COORDINATIONS_REQUEST
    DISSOCIATE_COORDINATIONS_SUCCESS
    DISSOCIATE_COORDINATIONS_FAILURE
    
    CHANGE_COORDINATIONS_DIALOG_DISSOCIATE
    
    SERVICES_DISPATCH_REQUEST
    SERVICES_DISPATCH_SUCCESS
    SERVICES_DISPATCH_FAILURE
    
    CHANGE_MONTH_YEAR
    
    LOAD_IRI_REQUEST
    LOAD_IRI_BY_USER_REQUEST
    LOAD_IRI_SUCCESS
    LOAD_IRI_FAILURE
    RESET_IRI
    
    LOAD_INDICATORS_REQUEST
    LOAD_INDICATORS_SUCCESS
    LOAD_INDICATORS_FAILURE
    
    SAVE_INDICATORS_REQUEST
    SAVE_INDICATORS_SUCCESS
    SAVE_INDICATORS_FAILURE
`,
    {
        prefix: '@regional.centers/'
    }
)

/**
 * Action types
 */
export interface IActionCreate {
    readonly center: RegionalCenter
}

export interface IActionFind {
    readonly id: string
}

export interface IActionLoad {
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly id: string
}

export interface IActionLoadAssociate {
    readonly centerId: string
    readonly userType: TypesUser
    readonly paginator?: IPaginator
}

export interface IActionLoadCoordinations {
    readonly centerId: string
    readonly paginator?: IPaginator
}

export interface IActionLoadCentersByUser extends IActionLoadAssociate {
    readonly userId: string
}

export interface IActionDissociate {
    readonly centerId: string
    readonly userType: TypesUser
    readonly user: User
}

export interface IActionAssociateCoordination {
    readonly centerId: string
    readonly coordination: Coordination
}

export interface IActionDissociateCoordination {
    readonly centerId: string
    readonly coordinationId: string
}

export interface IActionLoadCalls {
    readonly regionalCenterId: string
    readonly monthYear: Date
    readonly coordinations?: string[]
    readonly serviceTypes?: string[]
    readonly sort?: string
}

export interface IActionLoadServiceLocations {
    readonly coordinations: Coordination[]
}

export interface IActionLoadIriByRegional {
    readonly regionalCenterId: string
    readonly monthYear: Date
    readonly paginator?: IPaginator
}

export interface IActionLoadIriByUser {
    readonly regionalCenterId: string
    readonly userId: string
    readonly monthYear: Date
}

export interface IActionLoadIndicatorsByRegional {
    readonly regionalCenterId: string
    readonly monthYear?: Date
}

export interface IActionCreateIndicatorsByRegional {
    readonly regionalCenterId: string
    readonly indicators: Indicator
}

/**
 * State types
 */
interface ICreateState extends IComponentState {
    readonly center: RegionalCenter
    readonly dialog: boolean
}

export interface IListState extends IComponentState {
    readonly centers: RegionalCenter[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly id: string
}

export interface IListCoordinationsState extends IComponentState {
    readonly coordinations: Coordination[]
    readonly paginator: IPaginator
    readonly dialogAssociate: boolean
    readonly associating: boolean
    readonly dialogDissociate: boolean
    readonly dissociating: boolean
    readonly dissociateId?: string
}

interface IListAssociates extends IListUserState {
    readonly associating: boolean
    readonly dialogAssociate: boolean
    readonly dissociating: boolean
    readonly dialogDissociate: boolean
    readonly dissociateId?: string
}

interface ILocationState extends IComponentState {
    readonly locations: ServiceLocation[]
}

interface IServiceDispatchesState extends IComponentState {
    readonly servicesDispatches: Call[]
    readonly paginator: IPaginator
}

interface IIriState extends IComponentState {
    readonly data: Iri[]
    readonly paginator: IPaginator
}

interface IIndicatorsComponent extends IComponentState {
    readonly data: Indicator
}


export interface IRegionalCenterState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly operators: IListAssociates
    readonly supervisors: IListAssociates
    readonly electricians: IListAssociates
    readonly locations: ILocationState
    readonly coordinations: IListCoordinationsState
    readonly selected: RegionalCenter
    readonly servicesDispatches: IServiceDispatchesState
    readonly iri: IIriState
    readonly monthYear: Date
    readonly indicators: IIndicatorsComponent
}
