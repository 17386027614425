import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import auth from './auth/reducer'
import layout from './layout/reducer'
import snackBar from './snack.bar/reducer'
import user from './user/reducer'
import regionalCenter from './regional.centers/reducer'
import coordination from './coordinations/reducer'
import serviceLocation from './service.location/reducer'
import reports from './reports/reducer'
import devices from './devices/reducer'

const createRootReducer = (history: History) => combineReducers({
    auth,
    router: connectRouter(history),
    snackBar,
    layout,
    user,
    regionalCenter,
    reports,
    coordination,
    serviceLocation,
    devices
})

export default createRootReducer
