import { JsonUtils } from '../../utils/json.util'

export default class Indicator {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _callbacks: number | undefined
    private _designation_time: number | undefined
    private _unfounded: number | undefined
    private _callbacks_returned: number | undefined
    private _tma: number | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get callbacks(): number | undefined {
        return this._callbacks
    }

    set callbacks(value: number | undefined) {
        this._callbacks = value
    }

    get designation_time(): number | undefined {
        return this._designation_time
    }

    set designation_time(value: number | undefined) {
        this._designation_time = value
    }

    get unfounded(): number | undefined {
        return this._unfounded
    }

    set unfounded(value: number | undefined) {
        this._unfounded = value
    }

    get callbacks_returned(): number | undefined {
        return this._callbacks_returned
    }

    set callbacks_returned(value: number | undefined) {
        this._callbacks_returned = value
    }

    get tma(): number | undefined {
        return this._tma
    }

    set tma(value: number | undefined) {
        this._tma = value
    }

    public fromJSON(json: any): Indicator {
        if (!json) {
            return this
        }
        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.callbacks !== undefined) {
            this.callbacks = json.callbacks
        }

        if (json.designation_time !== undefined) {
            this.designation_time = json.designation_time
        }

        if (json.unfounded !== undefined) {
            this.unfounded = json.unfounded
        }

        if (json.callbacks_returned !== undefined) {
            this.callbacks_returned = json.callbacks_returned
        }

        if (json.tma !== undefined) {
            this.tma = json.tma
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id || undefined,
            created_at: this.created_at || undefined,
            updated_at: this.updated_at || undefined,
            callbacks: this.callbacks || undefined,
            designation_time: this.designation_time || undefined,
            unfounded: this.unfounded || undefined,
            callbacks_returned: this.callbacks_returned || undefined,
            tma: this.tma || undefined
        }
    }
}
