import { Reducer } from 'redux'
import { CoordinationTypes, ICoordinationState } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../root.types'
import Coordination from '../../application/models/regional.center/coordination'
import ServiceLocation from '../../application/models/regional.center/service.location'

export const INITIAL_STATE: ICoordinationState = {
    create: {
        coordination: new Coordination(),
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    list: {
        coordinations: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        dialog: false,
        id: '',
        loading: false,
        success: false,
        error: false
    },
    selected: new Coordination(),
    serviceLocation: {
        locations: [],
        loading: false,
        success: false,
        error: false,
        associating: false,
        dialogAssociate: false,
        dialogDissociate: false,
        dissociateId: '',
        dissociating: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    }
}

export const resetCreate = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, create: INITIAL_STATE.create }
}

export const resetList = (state: ICoordinationState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const changeCoordination = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    coordination: Coordination
}>) => {
    const { coordination } = action.payload
    return { ...state, create: { ...state.create, coordination } }
}

export const changePaginator = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    paginator?: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, list: { ...state.list, paginator } }
}

export const changeSearchPaginator = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    search: ISearch
}>) => {
    const { search } = action.payload
    return {
        ...state,
        list: {
            ...state.list,
            paginator: {
                ...state.list.paginator,
                search
            }
        }
    }
}

export const changeRemoveDialog = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    id: string
}>) => {
    const { dialog, id } = action.payload
    return { ...state, remove: { ...state.remove, dialog, id } }
}

export const changeDialog = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    coordination: Coordination
}>) => {
    const { dialog, coordination } = action.payload
    return {
        ...state,
        create: {
            ...state.create,
            dialog, coordination: coordination || state.create.coordination
        }
    }
}

export const createRequest = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const createSuccess = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    coordination: Coordination
}>) => {
    const { coordination } = action.payload
    return {
        ...state,
        create: success({ ...state.create, coordination, dialog: true })
    }
}

export const createFailure = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, create: failure(state.create) }
}

export const loadRequest = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    coordinations: Coordination[],
    headers
}>) => {
    const { coordinations, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, list: success({ ...state.list, coordinations, paginator }) }
}

export const loadFailure = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreRequest = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    paginator: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, list: request({ ...state.list, paginator }) }
}

export const loadMoreSuccess = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    coordinations: Coordination[],
    headers
}>) => {
    const { coordinations, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    const stateCoordinations = state.list.coordinations.concat(coordinations)
    return {
        ...state,
        list: success({ ...state.list, coordinations: stateCoordinations, paginator })
    }
}

export const updateSuccess = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    coordination: Coordination
}>) => {
    const { coordination } = action.payload
    return { ...state, create: success({ ...state.create, coordination }) }
}

export const removeRequest = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, remove: request({ ...state.remove, dialog: true }) }
}

export const removeSuccess = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    id: string
}>) => {
    const { id } = action.payload
    const coordinations = state.list.coordinations?.filter(coord => coord?.id !== id)
    return {
        ...state,
        list: {
            ...state.list,
            coordinations
        },
        remove: success({ ...INITIAL_STATE.remove })
    }
}

export const removeFailure = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, remove: failure(state.remove) }
}

export const loadLocationsRequest = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, serviceLocation: request(state.serviceLocation) }
}

export const loadLocationsSuccess = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    locations: ServiceLocation[],
    headers
}>) => {
    const { locations, headers } = action.payload
    const paginator = {
        ...state.serviceLocation.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, serviceLocation: success({ ...state.serviceLocation, locations, paginator }) }
}

export const loadLocationsFailure = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, serviceLocation: failure(state.serviceLocation) }
}
export const resetLocationsList = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, serviceLocation: INITIAL_STATE.serviceLocation }
}

export const changeAssociatePaginator = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    paginator?: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, serviceLocation: success({ ...state.serviceLocation, paginator }) }
}

export const changeAssociateSearchPaginator = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    search: ISearch
}>) => {
    const { search } = action.payload
    return {
        ...state,
        serviceLocation: {
            ...state.serviceLocation,
            paginator: {
                ...state.serviceLocation.paginator,
                search
            }
        }
    }
}

export const changeAssociateDialog = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    dialog: boolean
}>) => {
    const { dialog } = action.payload
    return {
        ...state,
        serviceLocation: {
            ...state.serviceLocation,
            dialogAssociate: dialog
        }
    }
}

export const changeDissociateDialog = (state: ICoordinationState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    locationId?: string
}>) => {
    const { dialog, locationId } = action.payload
    return {
        ...state,
        serviceLocation: {
            ...state.serviceLocation,
            dialogDissociate: dialog,
            dissociateId: locationId
        }
    }
}

export const associateLocationRequest = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, serviceLocation: { ...state.serviceLocation, associating: true } }
}

export const associateLocationEnded = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, serviceLocation: { ...state.serviceLocation, associating: false, dialogAssociate: false } }
}

export const dissociateLocationRequest = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, serviceLocation: { ...state.serviceLocation, dissociating: true } }
}

export const dissociateLocationEnded = (state: ICoordinationState = INITIAL_STATE) => {
    return { ...state, serviceLocation: { ...state.serviceLocation, dissociating: false, dialogDissociate: false } }
}

const reducer: Reducer<ICoordinationState> = createReducer<ICoordinationState>(INITIAL_STATE, {
    [CoordinationTypes.RESET_CREATE]: resetCreate,
    [CoordinationTypes.RESET_LIST]: resetList,

    [CoordinationTypes.CHANGE_COORDINATION]: changeCoordination,
    [CoordinationTypes.CHANGE_PAGINATOR]: changePaginator,
    [CoordinationTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,
    [CoordinationTypes.CHANGE_REMOVE_DIALOG]: changeRemoveDialog,
    [CoordinationTypes.CHANGE_DIALOG]: changeDialog,

    [CoordinationTypes.CREATE_REQUEST]: createRequest,
    [CoordinationTypes.CREATE_SUCCESS]: createSuccess,
    [CoordinationTypes.CREATE_FAILURE]: createFailure,

    [CoordinationTypes.LOAD_REQUEST]: loadRequest,
    [CoordinationTypes.LOAD_SUCCESS]: loadSuccess,
    [CoordinationTypes.LOAD_FAILURE]: loadFailure,

    [CoordinationTypes.LOAD_MORE_REQUEST]: loadMoreRequest,
    [CoordinationTypes.LOAD_MORE_SUCCESS]: loadMoreSuccess,
    [CoordinationTypes.LOAD_MORE_FAILURE]: loadFailure,

    [CoordinationTypes.FIND_REQUEST]: createRequest,
    [CoordinationTypes.FIND_SUCCESS]: updateSuccess,
    [CoordinationTypes.FIND_FAILURE]: createFailure,

    [CoordinationTypes.UPDATE_REQUEST]: createRequest,
    [CoordinationTypes.UPDATE_SUCCESS]: updateSuccess,
    [CoordinationTypes.UPDATE_FAILURE]: createFailure,

    [CoordinationTypes.REMOVE_REQUEST]: removeRequest,
    [CoordinationTypes.REMOVE_SUCCESS]: removeSuccess,
    [CoordinationTypes.REMOVE_FAILURE]: removeFailure,

    [CoordinationTypes.LOAD_LOCATIONS_REQUEST]: loadLocationsRequest,
    [CoordinationTypes.LOAD_LOCATIONS_SUCCESS]: loadLocationsSuccess,
    [CoordinationTypes.LOAD_LOCATIONS_FAILURE]: loadLocationsFailure,

    [CoordinationTypes.RESET_LOCATIONS_LIST]: resetLocationsList,

    [CoordinationTypes.CHANGE_ASSOCIATES_PAGINATOR]: changeAssociatePaginator,
    [CoordinationTypes.CHANGE_ASSOCIATES_SEARCH_PAGINATOR]: changeAssociateSearchPaginator,

    [CoordinationTypes.CHANGE_ASSOCIATE_DIALOG]: changeAssociateDialog,
    [CoordinationTypes.CHANGE_DISSOCIATE_DIALOG]: changeDissociateDialog,

    [CoordinationTypes.ASSOCIATE_LOCATION_REQUEST]: associateLocationRequest,
    [CoordinationTypes.ASSOCIATE_LOCATION_SUCCESS]: associateLocationEnded,
    [CoordinationTypes.ASSOCIATE_LOCATION_FAILURE]: associateLocationEnded,

    [CoordinationTypes.DISSOCIATE_LOCATION_REQUEST]: dissociateLocationRequest,
    [CoordinationTypes.DISSOCIATE_LOCATION_SUCCESS]: dissociateLocationEnded,
    [CoordinationTypes.DISSOCIATE_LOCATION_FAILURE]: dissociateLocationEnded
})

export default reducer
