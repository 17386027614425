import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { CenterTypes } from './types'
import RegionalCenter from '../../application/models/regional.center/regional.center'
import User, { TypesUser } from '../../application/models/users/users'
import { changeBreadCrumbLast } from '../layout/actions'
import Coordination from '../../application/models/regional.center/coordination'
import Call from '../../application/models/call/call'
import ServiceLocation from '../../application/models/regional.center/service.location'
import Iri from '../../application/models/regional.center/iri'
import Indicator from '../../application/models/regional.center/indicator'

/* Action for reset state */
export const resetCreate = () => action(CenterTypes.RESET_CREATE)

export const resetList = () => action(CenterTypes.RESET_LIST)

/* Actions for change Regional Center */
export const changeCenter = (center: RegionalCenter) => action(CenterTypes.CHANGE_CENTER, { center })

export const changePaginator = (paginator?: IPaginator) => [
    action(CenterTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    CenterTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveDialog = (dialog: boolean, id: string) => action(
    CenterTypes.CHANGE_REMOVE_DIALOG,
    { dialog, id }
)

export const changeDialog = (dialog: boolean, center?: RegionalCenter) => {
    const pipe: any = []
    if (!dialog) {
        pipe.push(resetCreate())
    }
    return pipe.concat(action(CenterTypes.CHANGE_DIALOG, { dialog, center }))

}

export const createRequest = (center: RegionalCenter) => action(CenterTypes.CREATE_REQUEST, { center })

export const createSuccess = (center: RegionalCenter) => action(CenterTypes.CREATE_SUCCESS, { center })

export const createFailure = () => action(CenterTypes.CREATE_FAILURE)

/* Actions for load Regional Centers */
export const loadRequest = (paginator?: IPaginator) => action(CenterTypes.LOAD_REQUEST, { paginator })

export const loadSuccess = (response: IAxiosResponse<RegionalCenter[]>) => action(CenterTypes.LOAD_SUCCESS, {
    centers: response.data,
    headers: response.headers
})

export const loadFailure = () => action(CenterTypes.LOAD_FAILURE)

export const loadMoreRequest = (paginator: IPaginator) => action(CenterTypes.LOAD_MORE_REQUEST, { paginator })

export const loadMoreSuccess = (response: IAxiosResponse<RegionalCenter[]>) => action(
    CenterTypes.LOAD_MORE_SUCCESS, {
        centers: response.data,
        headers: response.headers
    })

export const loadMoreFailure = () => action(CenterTypes.LOAD_MORE_FAILURE)


/* Actions for find Regional Center */
export const findRequest = (id: string) => [
    action(CenterTypes.FIND_REQUEST, { id }),
    loadIndicatorsRequest(id)
]

export const findSuccess = (center: RegionalCenter) => action(CenterTypes.FIND_SUCCESS, { center })

export const findFailure = () => action(CenterTypes.FIND_FAILURE)

/* Actions for update Regional Center */
export const updateRequest = (center: RegionalCenter) => action(CenterTypes.UPDATE_REQUEST, { center })

export const updateSuccess = (center: RegionalCenter) => action(CenterTypes.UPDATE_SUCCESS, { center })

export const updateFailure = () => action(CenterTypes.UPDATE_FAILURE)

/* Actions for remove Regional Center */
export const removeRequest = (id: string) => action(CenterTypes.REMOVE_REQUEST, { id })

export const removeSuccess = (id: string) => action(CenterTypes.REMOVE_SUCCESS, { id })

export const removeFailure = () => action(CenterTypes.REMOVE_FAILURE)

/* Actions to load users associated with a Regional Center */
export const loadAssociatesRequest = (centerId: string, userType: TypesUser, paginator?: IPaginator) => action(
    CenterTypes.LOAD_ASSOCIATES_REQUEST,
    { centerId, userType, paginator }
)

export const loadAssociatesSuccess = (userType: TypesUser, response: IAxiosResponse<User[]>) => action(
    CenterTypes.LOAD_ASSOCIATES_SUCCESS,
    {
        userType,
        users: response.data,
        headers: response.headers
    })

export const loadAssociatesFailure = (userType: TypesUser) => action(
    CenterTypes.LOAD_ASSOCIATES_FAILURE,
    { userType }
)

export const loadMoreAssociatesRequest = (centerId: string, userType: TypesUser, paginator?: IPaginator) => action(
    CenterTypes.LOAD_MORE_ASSOCIATES_REQUEST,
    { userType, paginator }
)

export const loadMoreAssociatesSuccess = (userType: TypesUser, response: IAxiosResponse<User[]>) => action(
    CenterTypes.LOAD_MORE_ASSOCIATES_SUCCESS,
    {
        userType,
        users: response.data,
        headers: response.headers
    })

export const loadMoreAssociatesFailure = (userType: TypesUser) => action(
    CenterTypes.LOAD_MORE_ASSOCIATES_FAILURE,
    { userType }
)

export const changeAssociatePaginator = (centerId: string, userType: TypesUser, paginator?: IPaginator) => [
    action(CenterTypes.CHANGE_ASSOCIATES_PAGINATOR, { userType, paginator }),
    loadAssociatesRequest(centerId, userType, paginator)
]

export const changeAssociateSearchPaginator = (userType: TypesUser, search: ISearch) => action(
    CenterTypes.CHANGE_ASSOCIATES_SEARCH_PAGINATOR,
    { userType, search }
)

/* Actions to associate a user with a Regional Center */
export const associatesUserRequest = (centerId: string, userType: TypesUser, user: User) => action(
    CenterTypes.ASSOCIATE_USER_REQUEST,
    { centerId, userType, user }
)

export const associatesUserSuccess = (userType: TypesUser, user: User) => action(
    CenterTypes.ASSOCIATE_USER_SUCCESS,
    { userType, user })

export const associatesUserFailure = (userType: TypesUser) => action(
    CenterTypes.ASSOCIATE_USER_FAILURE,
    { userType }
)

export const changeAssociateDialog = (dialog: boolean, userType: TypesUser) => action(
    CenterTypes.CHANGE_ASSOCIATE_DIALOG,
    { dialog, userType }
)

/* Actions to dissociate a user with a Regional Center */
export const dissociatesUserRequest = (centerId: string, userType: TypesUser, user: User) => action(
    CenterTypes.DISSOCIATE_USER_REQUEST,
    { centerId, userType, user }
)

export const dissociatesUserSuccess = (userType: TypesUser, user: User) => action(
    CenterTypes.DISSOCIATE_USER_SUCCESS,
    { userType, user })

export const dissociatesUserFailure = (userType: TypesUser) => action(
    CenterTypes.DISSOCIATE_USER_FAILURE,
    { userType }
)

export const changeDissociateDialog = (dialog: boolean, userType: TypesUser, id?: string) => action(
    CenterTypes.CHANGE_DISSOCIATE_DIALOG,
    { dialog, userType, id }
)

/* Actions to load regionals by the user */
export const loadCentersByUserRequest = (userType: TypesUser, userId: string) => action(
    CenterTypes.LOAD_CENTERS_BY_USER_REQUEST,
    { userType, userId }
)

export const loadCentersByUserSuccess = (response: IAxiosResponse<RegionalCenter[]>) => action(
    CenterTypes.LOAD_CENTERS_BY_USER_SUCCESS,
    {
        centers: response.data,
        headers: response.headers
    }
)

export const loadCentersByUserFailure = () => action(CenterTypes.LOAD_CENTERS_BY_USER_FAILURE)

export const changeRegionalSelected = (center: RegionalCenter, changeBreadCrumb?: boolean) => {
    const result: any[] = [
        action(CenterTypes.CHANGE_REGIONAL_SELECTED, { center }),
        loadIndicatorsRequest(`${center.id}`)
    ]
    if (changeBreadCrumb || changeBreadCrumb === undefined) {
        result.push(changeBreadCrumbLast(center?.name))
    }
    return result
}

/* Actions to load coorinations by the regional */
export const loadCoordinationsRequest = (centerId: string, paginator?: IPaginator) => action(
    CenterTypes.LOAD_COORDINATIONS_REQUEST,
    { centerId, paginator }
)

export const loadCoordinationsSuccess = (response: IAxiosResponse<Coordination[]>) => [
    action(
        CenterTypes.LOAD_COORDINATIONS_SUCCESS,
        {
            coordinations: response.data,
            headers: response.headers
        }
    ),
    loadLocationsRequest(response.data)
]

export const loadCoordinationsFailure = () => action(CenterTypes.LOAD_COORDINATIONS_FAILURE)


/* Actions to load locations by the regional */
export const loadLocationsRequest = (coordinations: Coordination[]) => action(
    CenterTypes.LOAD_LOCATIONS_REQUEST,
    { coordinations }
)

export const loadLocationsSuccess = (locations: ServiceLocation[]) => action(
    CenterTypes.LOAD_LOCATIONS_SUCCESS,
    { locations }
)

export const loadLocationsFailure = () => action(CenterTypes.LOAD_LOCATIONS_FAILURE)

export const resetCoordinationsList = () => action(CenterTypes.RESET_COORDINATIONS_LIST)

export const changeCoordinationPaginator = (centerId: string, paginator?: IPaginator) => [
    action(CenterTypes.CHANGE_COORDINATIONS_PAGINATOR, { paginator }),
    loadCoordinationsRequest(centerId, paginator)
]

export const changeCoordinationSearchPaginator = (search: ISearch) => action(
    CenterTypes.CHANGE_COORDINATIONS_SEARCH_PAGINATOR,
    { search }
)

export const changeCoordinationDialogAssociate = (dialog: boolean) => action(
    CenterTypes.CHANGE_COORDINATIONS_DIALOG_ASSOCIATE,
    { dialog }
)

export const changeCoordinationDialogDissociate = (dialog: boolean, coordinationId?: string) => action(
    CenterTypes.CHANGE_COORDINATIONS_DIALOG_DISSOCIATE,
    { dialog, coordinationId }
)

/* Actions to associate a coordinations with a Regional Center */
export const associatesCoordinationRequest = (centerId: string, coordination: Coordination) => action(
    CenterTypes.ASSOCIATE_COORDINATIONS_REQUEST,
    { centerId, coordination }
)

export const associatesCoordinationSuccess = (centerId: string, coordination: Coordination) => action(
    CenterTypes.ASSOCIATE_COORDINATIONS_SUCCESS,
    { centerId, coordination }
)

export const associatesCoordinationFailure = (centerId: string, coordinationId: string) => action(
    CenterTypes.ASSOCIATE_COORDINATIONS_FAILURE,
    { centerId, coordinationId }
)

/* Actions to dissociate a coordinations with a Regional Center */
export const dissociatesCoordinationRequest = (centerId: string, coordinationId: string) => action(
    CenterTypes.DISSOCIATE_COORDINATIONS_REQUEST,
    { centerId, coordinationId }
)

export const dissociatesCoordinationSuccess = (centerId: string, coordinationId: string) => action(
    CenterTypes.DISSOCIATE_COORDINATIONS_SUCCESS,
    { centerId, coordinationId }
)

export const dissociatesCoordinationFailure = (centerId: string, coordinationId: string) => action(
    CenterTypes.DISSOCIATE_COORDINATIONS_FAILURE,
    { centerId, coordinationId }
)

/* Actions for loading the dispatches of services from a Regional Center */
export const servicesDispatchesRequest = (
    regionalCenterId: string,
    monthYear: Date,
    coordinations?: string[],
    serviceTypes?: string[],
    sort?: string) => action(
    CenterTypes.SERVICES_DISPATCH_REQUEST,
    { regionalCenterId, monthYear, coordinations, serviceTypes, sort }
)

export const servicesDispatchesSuccess = (response: IAxiosResponse<Call[]>) => action(
    CenterTypes.SERVICES_DISPATCH_SUCCESS,
    { servicesDispatches: response.data, headers: response.headers }
)

export const servicesDispatchesFailure = () => action(CenterTypes.SERVICES_DISPATCH_FAILURE)

export const changeMonthYear = (monthYear: Date) => action(CenterTypes.CHANGE_MONTH_YEAR, { monthYear })

/* Actions for loading the I.R.I from a Regional Center */

export const loadIriRequest = (regionalCenterId: string, monthYear: Date, paginator: IPaginator) => action(
    CenterTypes.LOAD_IRI_REQUEST,
    { regionalCenterId, monthYear, paginator }
)

export const loadIriByUserRequest = (regionalCenterId: string, userId: string, monthYear: Date) => action(
    CenterTypes.LOAD_IRI_BY_USER_REQUEST,
    { regionalCenterId, monthYear, userId }
)

export const loadIriSuccess = (response: IAxiosResponse<Iri[]>) => action(
    CenterTypes.LOAD_IRI_SUCCESS,
    response
)

export const loadIriFailure = () => action(CenterTypes.LOAD_IRI_FAILURE)

export const resetIri = () => action(CenterTypes.RESET_IRI)

/* Actions for indicators */

export const saveIndicatorsRequest = (regionalCenterId: string, indicators: Indicator) => action(
    CenterTypes.SAVE_INDICATORS_REQUEST,
    { regionalCenterId, indicators }
)

export const saveIndicatorsSuccess = (regionalCenterId: string, indicators: Indicator) => action(
    CenterTypes.SAVE_INDICATORS_SUCCESS,
    { regionalCenterId, indicators }
)

export const saveIndicatorsFailure = () => action(CenterTypes.SAVE_INDICATORS_FAILURE)

export const loadIndicatorsRequest = (regionalCenterId: string, monthYear?: Date) => action(
    CenterTypes.LOAD_INDICATORS_REQUEST,
    { regionalCenterId, monthYear }
)

export const loadIndicatorsSuccess = (regionalCenterId: string, indicators: Indicator) => action(
    CenterTypes.LOAD_INDICATORS_SUCCESS,
    { regionalCenterId, indicators }
)

export const loadIndicatorsFailure = () => action(CenterTypes.LOAD_INDICATORS_FAILURE)
