import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import ServiceLocation from '../store/application/models/regional.center/service.location'
import axiosInstance from './axios'
import { AxiosResponse } from 'axios'
import Coordination from '../store/application/models/regional.center/coordination'

class ServiceLocationService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(location: ServiceLocation): Promise<ServiceLocation> {
        const url: string = `${this.apiVersion}/servicelocations`
        return axiosInstance
            .post(url, location.toJSON())
            .then(response => new ServiceLocation().fromJSON(response.data))
    }

    public getAll(paginator?: IPaginator): Promise<IAxiosResponse<ServiceLocation[]>> {
        const params: URLSearchParams = this.buildParams(paginator)
        return axiosInstance
            .get(`${this.apiVersion}/servicelocations`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(location => new ServiceLocation().fromJSON(location)),
                    headers: response.headers
                }
            })
    }

    public getAllByCoordination(coordination: Coordination, paginator?: IPaginator): Promise<IAxiosResponse<ServiceLocation[]>> {
        const serviceLocationsIds = coordination?.service_locations?.map((item: ServiceLocation) => item?.id) || []
        if (!serviceLocationsIds?.length) {
            return Promise.resolve(
                {
                    data: [],
                    headers: {
                        'x-total-count': 0
                    }
                }
            )
        }
        const params = new URLSearchParams()

        params.append('_id', `${serviceLocationsIds.join(',')}`)

        if (paginator?.page) {
            params.append('page', String(paginator.page + 1))
        }

        if (paginator?.rows) {
            params.append('limit', String(paginator.rows))
        }
        return axiosInstance
            .get(`${this.apiVersion}/servicelocations`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(location => new ServiceLocation().fromJSON(location)),
                    headers: response.headers
                }
            })
    }

    public getById(id: string): Promise<ServiceLocation> {
        return axiosInstance.get(`${this.apiVersion}/servicelocations/${id}`)
            .then(response => new ServiceLocation().fromJSON(response?.data))
    }

    public buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (!!paginator?.search?.key && !!paginator?.search?.value)
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                (paginator?.search?.key === 'district' || paginator?.search?.key === 'city') ?
                    params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
                    :
                    params.append(paginator?.search?.key, `${paginator?.search?.value}`)
            }
        }
        return params
    }

    public update(location: ServiceLocation): Promise<ServiceLocation> {
        return axiosInstance
            .patch(`${this.apiVersion}/servicelocations/${location?.id}`, location.toJSON())
            .then(response => new ServiceLocation().fromJSON(response.data))
    }

    public remove(id: string): Promise<void> {
        return axiosInstance.delete(`${this.apiVersion}/servicelocations/${id}`)
    }
}

export default new ServiceLocationService()
