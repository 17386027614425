import { all, apply, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { IActionType, IAxiosResponse } from '../root.types'
import { open } from '../snack.bar/actions'
import { SnackBarMessageType } from '../../../components/snackbar'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, ServiceLocationTypes } from './types'

import {
    createFailure,
    createSuccess,
    findFailure,
    findSuccess,
    loadFailure,
    loadMoreFailure,
    loadMoreSuccess,
    loadRequest,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { changeBreadCrumbLast } from '../layout/actions'
import locationService from '../../../services/service.location'

function* create(action: IActionType<IActionCreate>) {
    try {
        const { location } = action.payload
        const response: any = yield apply(locationService, locationService.create, [location])
        yield put<any>(createSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.SERVICE_LOCATION.CREATE.MESSAGE'))
        yield call(reloadServicesLocation)
    } catch (e) {
        yield put(createFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(
            locationService,
            locationService.getAll,
            [paginator]
        )
        yield put<any>(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

function* getById(action: IActionType<IActionFind>) {
    yield put(changeBreadCrumbLast(''))
    const { id } = action.payload
    try {
        const response: any = yield apply(locationService, locationService.getById, [id])
        yield put(findSuccess(response))
        yield put(changeBreadCrumbLast(response?.district))
    } catch (e) {
        yield put(findFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    try {
        const { location } = action.payload
        const response: any = yield apply(locationService, locationService.update, [location])
        yield put<any>(updateSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.SERVICE_LOCATION.UPDATE.MESSAGE'))
        yield call(reloadServicesLocation)
    } catch (e) {
        yield put(updateFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(locationService, locationService.remove, [id])
        yield put<any>(removeSuccess(id))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.SERVICE_LOCATION.REMOVE.MESSAGE'))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* loadMore(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<any[]> = yield apply(
            locationService,
            locationService.getAll,
            [paginator]
        )
        yield put<any>(loadMoreSuccess(response))
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* reloadServicesLocation() {
    yield put<any>(loadRequest())
}

export default function* ServiceLocationSaga() {
    return yield all([
        takeLatest(ServiceLocationTypes.CREATE_REQUEST, create),
        takeLatest(ServiceLocationTypes.LOAD_REQUEST, getAll),
        takeLatest(ServiceLocationTypes.FIND_REQUEST, getById),
        takeLatest(ServiceLocationTypes.UPDATE_REQUEST, update),
        takeLatest(ServiceLocationTypes.REMOVE_REQUEST, remove),
        takeEvery(ServiceLocationTypes.LOAD_MORE_REQUEST, loadMore)
    ])
}
