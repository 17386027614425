import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../root.types'
import Call from '../../application/models/call/call'

export const ReportTypes = createTypes(`    
    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE     
`,
    {
        prefix: '@reports/'
    }
)

/**
 * Action Types
 */

export interface CallFilter {
    readonly coordinations: any[]
    readonly locations: any[]
    readonly date: string,
    readonly type: string
}

export interface IActionLoad {
    readonly paginator: IPaginator
}

interface IListState extends IComponentState {
    readonly calls: Call[]
}

export interface IReportState {
    readonly list: IListState
}
