import moment from 'moment'
import { IAxiosResponse } from '../store/ducks/root.types'
import Call from '../store/application/models/call/call'
import axiosInstance from './axios'
import { AxiosResponse } from 'axios'

export const ROWS: Call[] = [
    new Call().fromJSON({
        'id': '62960f9b4ce36a3d3fcd2c9e',
        'service_number': 76048123,
        'id_coverage': 'IS',
        'label_ope': 'ZNA25280',
        'region': 'PE',
        'local': '540',
        'priority': 'C05',
        'substation': 'CCD',
        'label_ref': 'LDA CAVALCANTE',
        'id_set_aneel': '15034.0',
        'dh_complains': '2020-04-29T13:09:00.000Z',
        'dh_scheduled': null,
        'dh_designates': '2020-04-15T14:36:00.000Z',
        'dh_activate': '2020-04-15T15:25:00.000Z',
        'dh_location': '2020-04-15T15:48:00.000Z',
        'dh_terreal': '2020-04-15T16:15:00.000Z',
        'service_type': 'RC19',
        'occurrence_type': 'E',
        'affected_device': '411157438',
        'closing_code': '8.81 VISTORIADO, DEFEITO INTERNO NA UNID.CONS.',
        'cause_code': 'nan',
        'description': null,
        'linked_complaints': 1,
        'recidivism': 2,
        'last_opr': '4000471',
        'first_opr': '4000470',
        'team1': '2244718.0',
        'team2': 'nan',
        'observation': null,
        'address': ' R FLORENTINA 12 CA6 12 CASCADURA',
        'consumer_registration': 411157438,
        'consumer_name': 'DJALMA COELHO MOREIRA FILHO',
        'cpf_cnpj': '79020062700',
        'phone': '21987703301',
        'proceed': 0.49309998750686646
    }),
    new Call().fromJSON({
        'region': 'PE',
        'service_number': '58871612',
        'priority': 'C03',
        'substation': 'CLG',
        'dh_complains': '2020-04-29T15:09:00.000Z',
        'dh_scheduled': '2018-01-02 09:09:51',
        'dh_designates': '2018-01-01 07:31:21',
        'dh_activate': '2018-01-01 08:45:00',
        'dh_location': '2018-01-01 08:55:01',
        'dh_terreal': '2018-01-01 09:09:51',
        'service_type': 'RC07',
        'address': 'AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO',
        'affected_device': '400022241',
        'proceed': '0.96',
        'consumer_registration': 412967288,
        'consumer_name': 'FELIPE REZENDE DE FARIA',
        'cpf_cnpj': '74322969787',
        'phone': '33951896',
        'recidivism': 3,
        'last_opr': '4007326',
        'first_opr': '4007328'
    }),
    new Call().fromJSON({
        'region': 'PE',
        'service_number': '58871612',
        'priority': 'C03',
        'substation': 'CLG',
        'dh_complains': '2020-04-30T13:09:00.000Z',
        'dh_scheduled': '2018-01-02 09:09:51',
        'dh_designates': '2018-01-01 07:31:21',
        'dh_activate': '2018-01-01 08:45:00',
        'dh_location': '2018-01-01 08:55:01',
        'dh_terreal': '2018-01-01 09:09:51',
        'service_type': 'RC75',
        'address': 'AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO',
        'affected_device': '400021520',
        'proceed': '0.97',
        'consumer_registration': 412967288,
        'consumer_name': 'FELIPE REZENDE DE FARIA',
        'cpf_cnpj': '74322969787',
        'phone': '33951896',
        'recidivism': 4,
        'first_opr': '4007326',
        'last_opr': '4007328'
    }),
    new Call().fromJSON({
        'region': 'PE',
        'service_number': '58871612',
        'priority': 'C03',
        'substation': 'CLG',
        'dh_complains': '2020-04-30T10:09:00.000Z',
        'dh_scheduled': '2018-01-02 09:09:51',
        'dh_designates': '2018-01-01 07:31:21',
        'dh_activate': '2018-01-01 08:45:00',
        'dh_location': '2018-01-01 08:55:01',
        'dh_terreal': '2018-01-01 09:09:51',
        'service_type': 'RC06',
        'address': 'AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO',
        'affected_device': '400022918',
        'proceed': '0.99',
        'consumer_registration': 412967288,
        'consumer_name': 'FELIPE REZENDE DE FARIA',
        'cpf_cnpj': '74322969787',
        'phone': '33951896',
        'recidivism': 2,
        'first_opr': '4007326',
        'last_opr': '4007328'
    }),
    new Call().fromJSON({
        'region': 'PE',
        'service_number': '58871612',
        'priority': 'C03',
        'substation': 'CLG',
        'dh_complains': '2020-04-30T09:09:00.000Z',
        'dh_scheduled': '2018-01-02 09:09:51',
        'dh_designates': '2018-01-01 07:31:21',
        'dh_activate': '2018-01-01 08:45:00',
        'dh_location': '2018-01-01 08:55:01',
        'dh_terreal': '2018-01-01 09:09:51',
        'service_type': 'RC07',
        'address': 'AV ERNANI CARDOSO, 154 - CASCADURA - RIO DE JANEIRO',
        'affected_device': '400022420',
        'proceed': '0.93',
        'consumer_registration': 412967288,
        'consumer_name': 'FELIPE REZENDE DE FARIA',
        'cpf_cnpj': '74322969787',
        'phone': '33951896',
        'recidivism': 2,
        'first_opr': '4007326',
        'last_opr': '4007328'
    })
]

class ServiceDispatchService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getAllByCoordinations(
        coordinatesNames: string[],
        monthYear: Date,
        serviceTypes?: string[],
        sort?: string): Promise<IAxiosResponse<Call[]>> {
        if (!coordinatesNames?.length) {
            return Promise.resolve(
                {
                    data: [],
                    headers: {
                        'x-total-count': 0
                    }
                }
            )
        }
        const startDate = moment(monthYear).startOf('month').format('YYYY-MM-DD')
        const endDate = moment(monthYear).endOf('month').format('YYYY-MM-DD')
        const params = new URLSearchParams()
        params.append('region', `${coordinatesNames.join(',')}`)
        params.append('dh_complains', `gte:${startDate}T00:00:00.000Z`)
        params.append('dh_complains', `lte:${endDate}T23:59:59.000Z`)
        params.append('limit', `20`)
        if (serviceTypes?.length) {
            const types: string = serviceTypes.join(',')
            params.append('service_type', types)
        }
        if (sort) {
            params.append('sort', sort)
        }
        return this.getAll(params)
    }

    public async getByServiceNumber(serviceNumber: string): Promise<Call> {
        return ROWS[0]
        // if (!serviceNumber) {
        //     return new Call()
        // }
        // const params = new URLSearchParams()
        // params.append('service_number', `${serviceNumber}`)
        // const result: IAxiosResponse<Call[]> = await this.getAll(params)
        // return result.data.length ? result.data[0] : new Call()

    }

    private getAll(params: URLSearchParams): Promise<IAxiosResponse<Call[]>> {
        return axiosInstance
            .get(`${this.apiVersion}/calls`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map((item: any) => new Call().fromJSON(item)),
                    headers: response.headers
                }
            })
    }

}

export default new ServiceDispatchService()
