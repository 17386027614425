import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Coordination from '../store/application/models/regional.center/coordination'
import ServiceLocation from '../store/application/models/regional.center/service.location'
import axiosInstance from './axios'
import { AxiosResponse } from 'axios'
import locationService from './service.location'

class CoordinationService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(coordination: Coordination): Promise<Coordination> {
        const url: string = `${this.apiVersion}/coordinations`
        const body = {
            ...coordination.toJSON(),
            service_locations: coordination
                ?.service_locations
                ?.map((serviceLocation: ServiceLocation) => serviceLocation?.id)
        }
        return axiosInstance
            .post(url, body)
            .then(response => new Coordination().fromJSON(response.data))
    }

    public getAll(paginator?: IPaginator): Promise<IAxiosResponse<Coordination[]>> {
        const params: URLSearchParams = this.buildParams(paginator)
        return axiosInstance
            .get(`${this.apiVersion}/coordinations`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(coordination => new Coordination().fromJSON(coordination)),
                    headers: response.headers
                }
            })
    }

    public getById(id: string): Promise<Coordination> {
        return axiosInstance.get(`${this.apiVersion}/coordinations/${id}`)
            .then(response => new Coordination().fromJSON(response?.data))
    }

    public buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (paginator?.search?.key === 'name' && paginator?.search?.value)
                || (paginator?.search?.key === 'email' && paginator?.search?.value)
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }
        return params
    }

    public update(coordination: Coordination): Promise<Coordination> {
        const body = {
            ...coordination.toJSON(),
            service_locations: coordination
                ?.service_locations
                ?.map((serviceLocation: ServiceLocation) => serviceLocation?.id)
        }
        return axiosInstance
            .patch(`${this.apiVersion}/coordinations/${coordination.id}`, body)
            .then(response => new Coordination().fromJSON(response.data))
    }

    public remove(id: string): Promise<void> {
        return axiosInstance.delete(`${this.apiVersion}/coordinations/${id}`)
    }

    public async getAllLocationsByCoordinationId(
        coordinationId: string,
        paginator?: IPaginator
    ): Promise<IAxiosResponse<ServiceLocation[]>> {
        const coordination: Coordination = await this.getById(coordinationId)
        return locationService.getAllByCoordination(coordination, paginator)
    }

    public associateServiceLocation(coordinationId: string, locationId: string): Promise<void> {
        return axiosInstance
            .post(`${this.apiVersion}/coordinations/${coordinationId}/servicelocations/${locationId}`)
    }

    public dissociateServiceLocation(coordinationId: string, locationId: string): Promise<void> {
        return axiosInstance
            .delete(`${this.apiVersion}/coordinations/${coordinationId}/servicelocations/${locationId}`)
    }
}

export default new CoordinationService()
