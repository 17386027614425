import { action } from 'typesafe-actions'
import { IAxiosResponse } from '../root.types'
import { DevicesTypes } from './types'
import Device from '../../application/models/call/device'

export const resetList = () => action(DevicesTypes.RESET_LIST)

/* Actions for load device */
export const loadRequest = (installations: string[]) => action(
    DevicesTypes.LOAD_REQUEST,
    { installations }
)

export const loadSuccess = (response: IAxiosResponse<Device[]>) => action(
    DevicesTypes.LOAD_SUCCESS, {
        data: response.data,
        headers: response.headers
    })

export const loadFailure = () => action(DevicesTypes.LOAD_FAILURE)
