import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import RegionalCenter from '../store/application/models/regional.center/regional.center'
import User, { MAP_TYPE_USER_TO_ENDPOINT, TypesUser } from '../store/application/models/users/users'
import Coordination from '../store/application/models/regional.center/coordination'
import axiosInstance from './axios'
import { AxiosResponse } from 'axios'
import Iri from '../store/application/models/regional.center/iri'
import Indicator from '../store/application/models/regional.center/indicator'

class RegionalCentersService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(center: RegionalCenter): Promise<RegionalCenter> {
        const url: string = `${this.apiVersion}/regionalcenters`
        return axiosInstance
            .post(url, center.toJSON())
            .then(response => new RegionalCenter().fromJSON(response.data))
    }

    public getAll(paginator?: IPaginator): Promise<IAxiosResponse<RegionalCenter[]>> {
        const params: URLSearchParams = this.buildParams(paginator)
        return axiosInstance
            .get(`${this.apiVersion}/regionalcenters`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(center => new RegionalCenter().fromJSON(center)),
                    headers: response.headers
                }
            })
    }

    public getById(id: string): Promise<RegionalCenter> {
        return axiosInstance.get(`${this.apiVersion}/regionalcenters/${id}`)
            .then(response => new RegionalCenter().fromJSON(response?.data))
    }

    public buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (paginator?.search?.key === 'name' && paginator?.search?.value)
                || (paginator?.search?.key === 'email' && paginator?.search?.value)
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }
        return params
    }

    public update(center: RegionalCenter): Promise<RegionalCenter> {
        return axiosInstance
            .patch(`${this.apiVersion}/regionalcenters/${center.id}`, center.toJSON())
            .then(response => new RegionalCenter().fromJSON(response.data))
    }

    public remove(id: string): Promise<void> {
        return axiosInstance.delete(`${this.apiVersion}/regionalcenters/${id}`)
    }

    public async getUsersAssociates(
        regionalCenterId: string,
        userType: TypesUser, paginator?: IPaginator
    ): Promise<IAxiosResponse<User[]>> {
        const params = new URLSearchParams()
        params.append('regional_centers', regionalCenterId)
        return axiosInstance
            .get(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(user => new User().fromJSON({ ...user, type: userType })),
                    headers: response.headers
                }
            })

    }

    public associateUser(regionalCenterId: string, userType: TypesUser, userId: string): Promise<User> {
        return axiosInstance
            .post(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}/regionalcenters/${regionalCenterId}`)
    }

    public dissociateUser(regionalCenterId: string, userType: TypesUser, userId: string): Promise<void> {
        return axiosInstance
            .delete(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}/regionalcenters/${regionalCenterId}`)
    }

    public async getCentersByUser(
        userId: string,
        userType: TypesUser,
        paginator?: IPaginator): Promise<IAxiosResponse<RegionalCenter[]>> {
        const usersParams: URLSearchParams = this.buildParams(paginator)
        if (userType === TypesUser.COORDINATOR) {
            return axiosInstance
                .get(`${this.apiVersion}/regionalcenters`)
                .then((response: AxiosResponse<any[]>) => {
                    return {
                        data: response.data.map(center => new RegionalCenter().fromJSON(center)),
                        headers: response.headers
                    }
                })
        }
        const url = `${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}`

        const usersResponse: any = await axiosInstance.get(url, { params: usersParams })

        const ids: string[] = usersResponse?.data?.regional_centers || []
        if (!ids?.length) {
            return {
                data: [],
                headers: {
                    'x-total-count': 0
                }
            }
        }
        const params = new URLSearchParams()
        params.append('_id', `${ids.join(',')}`)

        return axiosInstance
            .get(`${this.apiVersion}/regionalcenters`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(center => new RegionalCenter().fromJSON(center)),
                    headers: response.headers
                }
            })
    }

    public async getCoordinationsByCenterId(
        centerId: string,
        paginator?: IPaginator): Promise<IAxiosResponse<Coordination[]>> {
        const coordinationParams: URLSearchParams = this.buildParams(paginator)
        const coordinationResponse: IAxiosResponse<any> = await axiosInstance
            .get(`${this.apiVersion}/regionalcenters/${centerId}/coordinations`, { params: coordinationParams })

        const ids: string[] = coordinationResponse?.data || []

        if (!ids?.length) {
            return {
                data: [],
                headers: {
                    'x-total-count': 0
                }
            }
        }

        const params = new URLSearchParams()
        params.append('_id', `${ids.join(',')}`)

        return axiosInstance
            .get(`${this.apiVersion}/coordinations`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map(coordination => new Coordination().fromJSON(coordination)),
                    headers: response.headers
                }
            })
    }

    public associateCoordination(centerId: string, coordinationId: string): Promise<void> {
        return axiosInstance
            .post(`${this.apiVersion}/regionalcenters/${centerId}/coordinations/${coordinationId}`)
    }

    public dissociateCoordination(centerId: string, coordinationId: string): Promise<void> {
        return axiosInstance
            .delete(`${this.apiVersion}/regionalcenters/${centerId}/coordinations/${coordinationId}`)
    }

    public async getIriByCenterId(
        centerId: string,
        monthYear: Date,
        paginator?: IPaginator): Promise<IAxiosResponse<Iri[]>> {
        const params = new URLSearchParams()
        if (paginator) {

            if (paginator.page) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

        }

        params.append('month', `${monthYear.getMonth()}`)
        params.append('year', `${monthYear.getFullYear()}`)
        params.append('sort', `-value`)

        return axiosInstance
            .get(`${this.apiVersion}/regionalcenters/${centerId}/iris`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map((iri: any) => new Iri().fromJSON(iri)),
                    headers: response.headers
                }
            })
    }

    public async getIriByCenterIdAndUserId(
        centerId: string,
        userId: string,
        monthYear: Date): Promise<IAxiosResponse<Iri[]>> {

        const params = new URLSearchParams()
        params.append('month', `${monthYear.getMonth()}`)
        params.append('year', `${monthYear.getFullYear()}`)

        return axiosInstance
            .get(`${this.apiVersion}/regionalcenters/${centerId}/users/${userId}/iris`, { params })
            .then((response: AxiosResponse<any>) => {
                return {
                    data: response.data.map((iri: any) => new Iri().fromJSON(iri)),
                    headers: response.headers
                }
            })
    }

    public async getIndicatorsByRegionalId(regionalId: string): Promise<Indicator> {
        return axiosInstance
            .get(`${this.apiVersion}/regionalcenters/${regionalId}/indicators`)
            .then((response: AxiosResponse<any>) => new Indicator().fromJSON(response.data))
    }

    public async createIndicatorsByRegionalId(regionalId: string, indicator: Indicator): Promise<Indicator> {
        return axiosInstance
            .post(`${this.apiVersion}/regionalcenters/${regionalId}/indicators`, indicator.toJSON())
            .then((response: AxiosResponse<any>) => new Indicator().fromJSON(response.data))
    }

    public async updateIndicatorsByRegionalId(regionalId: string, indicator: Indicator): Promise<Indicator> {
        const body: any = indicator.toJSON()
        return axiosInstance
            .patch(`${this.apiVersion}/regionalcenters/${regionalId}/indicators/${indicator?.id}`, body)
            .then((response: AxiosResponse<any>) => new Indicator().fromJSON(response.data))

    }

}

export default new RegionalCentersService()
