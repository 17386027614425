import { lazy } from 'react'
import { SCOPES } from '../store/application/models/auth/scopes'
import { TypesUser } from '../store/application/models/users/users'

const ListOfRegionalCenters = lazy(() => import('../containers/regional.centers/list'))
const DetailsOfRegionalCenter = lazy(() => import('../containers/regional.centers/details'))
const ReportsOfRegionalCenter = lazy(() => import('../containers/regional.centers/reports'))
const ServicesOfRegionalCenter = lazy(() => import('../containers/regional.centers/service.dispatch'))
const Home = lazy(() => import('../containers/home/home'))

export const CENTERS_ROUTES = [
    {
        path: '/app/regional_centers',
        component: ListOfRegionalCenters,
        exact: true,
        private: true,
        scopes: [SCOPES.REGIONAL_READ_ALL]
    },
    {
        path: '/app/regional_centers/:centerId',
        component: DetailsOfRegionalCenter,
        exact: true,
        private: true,
        scopes: [SCOPES.REGIONAL_READ]
    },
    {
        path: '/app/regional_center',
        redirect: '/app/home',
        exact: true,
        private: true,
        scopes: [SCOPES.REGIONAL_READ]
    },
    {
        path: '/app/regional_center/:centerId',
        component: DetailsOfRegionalCenter,
        exact: true,
        private: true,
        scopes: [SCOPES.REGIONAL_READ]
    },
    {
        path: '/app/reports',
        component: Home,
        exact: true,
        private: true,
        userTypes: [
            TypesUser.COORDINATOR,
            TypesUser.SUPERVISOR,
            TypesUser.OPERATOR,
            TypesUser.ELECTRICIAN
        ]
    },
    {
        path: '/app/reports/:centerId',
        component: ReportsOfRegionalCenter,
        exact: true,
        private: true,
        userTypes: [
            TypesUser.COORDINATOR,
            TypesUser.SUPERVISOR,
            TypesUser.OPERATOR,
            TypesUser.ELECTRICIAN
        ]
    },
    {
        path: '/app/service_dispatches',
        component: Home,
        exact: true,
        private: true,
        scopes: [SCOPES.REGIONAL_READ],
        userTypes: [
            TypesUser.COORDINATOR,
            TypesUser.SUPERVISOR,
            TypesUser.OPERATOR,
            TypesUser.ELECTRICIAN
        ]
    },
    {
        path: '/app/service_dispatches/:centerId',
        component: ServicesOfRegionalCenter,
        exact: true,
        private: true,
        userTypes: [
            TypesUser.ADMINISTRATOR,
            TypesUser.COORDINATOR,
            TypesUser.SUPERVISOR,
            TypesUser.OPERATOR,
            TypesUser.ELECTRICIAN
        ]
    }
]
