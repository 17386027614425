import { IAxiosResponse } from '../store/ducks/root.types'
import Device from '../store/application/models/call/device'
import { AxiosResponse } from 'axios'
import axiosInstance from './axios'

class DeviceService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getByInstallations(installations: string[]): Promise<IAxiosResponse<Device[]>> {
        if (!installations?.length) {
            return Promise.resolve(
                {
                    data: [],
                    headers: {
                        'x-total-count': 0
                    }
                }
            )
        }
        const params = new URLSearchParams()
        params.append('installation', `${installations.join(',')}`)
        return this.getAll(params)
    }

    private getAll(params: URLSearchParams): Promise<IAxiosResponse<Device[]>> {
        return axiosInstance
            .get(`${this.apiVersion}/devices`, { params })
            .then((response: AxiosResponse<any[]>) => {
                return {
                    data: response.data.map((device: any) => new Device().fromJSON(device)),
                    headers: response.headers
                }
            })
    }
}

export default new DeviceService()
