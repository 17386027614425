import { Reducer } from 'redux'
import { IUserState, UserTypes } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../root.types'
import User, { TypesUser } from '../../application/models/users/users'

export const INITIAL_STATE: IUserState = {
    create: {
        user: new User(),
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    list: {
        users: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        dialog: false,
        id: '',
        loading: false,
        success: false,
        error: false
    },
    total: {
        totalCoordinators: 0,
        totalSupervisors: 0,
        totalOperators: 0,
        totalElectricians: 0,
        loading: false,
        success: false,
        error: false
    }
}

export const resetCreate = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: INITIAL_STATE.create }
}

export const resetList = (state: IUserState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const changeUser = (state: IUserState = INITIAL_STATE, action: IActionType<{ user: any }>) => {
    const { user } = action.payload
    return { ...state, create: { ...state.create, user } }
}

export const changePaginator = (state: IUserState = INITIAL_STATE, action: IActionType<{
    userType: TypesUser,
    paginator?: IPaginator
}>) => {
    const { userType, paginator } = action.payload
    return { ...state, list: { ...state.list, paginator, userType } }
}

export const changeSearchPaginator = (state: IUserState = INITIAL_STATE, action: IActionType<{ search: ISearch }>) => {
    const { search } = action.payload
    return {
        ...state,
        list: {
            ...state.list,
            paginator: {
                ...state.list.paginator,
                search
            }
        }
    }
}

export const changeRemoveDialog = (state: IUserState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    id: string,
    userType: TypesUser
}>) => {
    const { dialog, id, userType } = action.payload
    return { ...state, remove: { ...state.remove, dialog, id, userType } }
}

export const changeDialog = (state: IUserState = INITIAL_STATE, action: IActionType<{ dialog: boolean, user }>) => {
    const { dialog, user } = action.payload
    return { ...state, create: { ...state.create, dialog, user: user || state.create.user } }
}


export const createRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const createSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ user }>) => {
    const { user } = action.payload
    return {
        ...state,
        create: success({ ...state.create, user, dialog: true })
    }
}

export const createFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, create: failure(state.create) }
}

export const updateSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ user }>) => {
    const { user } = action.payload
    return { ...state, create: success({ ...state.create, user }) }
}

export const loadRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ users: any[], headers }>) => {
    const { users, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, list: success({ ...state.list, users, paginator }) }
}

export const loadFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreRequest = (state: IUserState = INITIAL_STATE, action: IActionType<{ userType: TypesUser, paginator: IPaginator }>) => {
    const { userType, paginator } = action.payload
    return { ...state, list: request({ ...state.list, paginator, userType }) }
}

export const loadMoreSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ users: User[], headers }>) => {
    const { users, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    const stateUsers = state.list.users.concat(users)
    return {
        ...state,
        list: success({ ...state.list, users: stateUsers, paginator })
    }
}

export const removeRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, remove: request({ ...state.remove, dialog: true }) }
}

export const removeSuccess = (state: IUserState = INITIAL_STATE, action: IActionType<{ userId: string }>) => {
    const { userId } = action.payload
    const users = state.list.users?.filter(user => user?.id !== userId)
    return {
        ...state,
        list: {
            ...state.list,
            users
        },
        remove: success({ ...INITIAL_STATE.remove })
    }
}

export const removeFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, remove: failure(state.remove) }
}


export const loadTotalRequest = (state: IUserState = INITIAL_STATE) => {
    return { ...state, total: request(state.total) }
}

export const loadTotalSuccess = (
    state: IUserState = INITIAL_STATE,
    action: IActionType<{
        totalCoordinators: number,
        totalSupervisors: number,
        totalOperators: number,
        totalElectricians: number
    }>) => {
    const {
        totalCoordinators,
        totalElectricians,
        totalSupervisors,
        totalOperators
    } = action.payload
    return {
        ...state,
        total: success({
            ...state.list,
            totalCoordinators,
            totalElectricians,
            totalSupervisors,
            totalOperators
        })
    }
}

export const loadTotalFailure = (state: IUserState = INITIAL_STATE) => {
    return { ...state, total: failure(state.total) }
}

const reducer: Reducer<IUserState> = createReducer<IUserState>(INITIAL_STATE, {
    [UserTypes.RESET_CREATE]: resetCreate,
    [UserTypes.RESET_LIST]: resetList,

    [UserTypes.CHANGE_USER]: changeUser,
    [UserTypes.CHANGE_PAGINATOR]: changePaginator,
    [UserTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,
    [UserTypes.CHANGE_REMOVE_DIALOG]: changeRemoveDialog,
    [UserTypes.CHANGE_DIALOG]: changeDialog,

    [UserTypes.CREATE_REQUEST]: createRequest,
    [UserTypes.CREATE_SUCCESS]: createSuccess,
    [UserTypes.CREATE_FAILURE]: createFailure,

    [UserTypes.LOAD_REQUEST]: loadRequest,
    [UserTypes.LOAD_SUCCESS]: loadSuccess,
    [UserTypes.LOAD_FAILURE]: loadFailure,

    [UserTypes.LOAD_MORE_REQUEST]: loadMoreRequest,
    [UserTypes.LOAD_MORE_SUCCESS]: loadMoreSuccess,
    [UserTypes.LOAD_MORE_FAILURE]: loadFailure,

    [UserTypes.FIND_REQUEST]: createRequest,
    [UserTypes.FIND_SUCCESS]: updateSuccess,
    [UserTypes.FIND_FAILURE]: createFailure,

    [UserTypes.UPDATE_REQUEST]: createRequest,
    [UserTypes.UPDATE_SUCCESS]: updateSuccess,
    [UserTypes.UPDATE_FAILURE]: createFailure,

    [UserTypes.REMOVE_REQUEST]: removeRequest,
    [UserTypes.REMOVE_SUCCESS]: removeSuccess,
    [UserTypes.REMOVE_FAILURE]: removeFailure,

    [UserTypes.LOAD_TOTAL_REQUEST]: loadTotalRequest,
    [UserTypes.LOAD_TOTAL_SUCCESS]: loadTotalSuccess,
    [UserTypes.LOAD_TOTAL_FAILURE]: loadTotalFailure
})

export default reducer
