import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { IActionType, IAxiosResponse } from '../root.types'
import { IActionLoad, ReportTypes } from './types'

import { loadFailure, loadSuccess } from './actions'
import callService from '../../../services/call'

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(
            callService,
            callService.getAll,
            [paginator]
        )
        yield put<any>(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

export default function* reportsSaga() {
    return yield all([
        takeLatest(ReportTypes.LOAD_REQUEST, getAll)
    ])
}
