/**
 * Action types
 */

import { LanguageOptions } from '../../../i18n'

export enum LayoutTypes {
    CHANGE_LANGUAGE = '@layout/CHANGE_LANGUAGE',
    CHANGE_USERNAME = '@layout/CHANGE_USERNAME',
    CHANGE_BREAD_CRUMB = '@layout/CHANGE_BREAD_CRUMB',
    CHANGE_EXPAND_NAV_BAR = '@layout/CHANGE_EXPAND_NAV_BAR'
}

interface AppBarState {
    readonly username: string
}

/**
 * State type
 */
export interface ILayoutState {
    readonly appBar: AppBarState
    readonly expandedNavbar: boolean
    readonly language: LanguageOptions
    readonly breadCrumbLast: string
}
