import { all, apply, call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import authService from '../../../services/auth'
import { AxiosResponse } from 'axios'
import {
    changePasswordFailure,
    changePasswordSuccess,
    forgotFailure,
    forgotSuccess,
    loginFailure,
    loginSuccess
} from './actions'
import { IActionType } from '../root.types'
import { AuthTypes, IAuth, IChangePassword, IForgot } from './types'
import { SnackBarMessageType } from '../../../components/snackbar'
import { open } from '../snack.bar/actions'
import { TypesUser } from '../../application/models/users/users'
import { LanguageOptions } from '../../../i18n'
import { changeLanguage, changeUsername } from '../layout/actions'
import userService from '../../../services/users'
import { AccessToken } from '../../application/models/auth/access.token'

export function* authenticate(action: IActionType<AxiosResponse<IAuth>>) {
    try {
        const { data } = action.payload
        const accessToken: AccessToken = yield apply(authService, authService.login, [data])
        yield put(loginSuccess())
        yield put(push(`/app/home`))
        yield call(getUserLogged, accessToken.sub, accessToken.sub_type)
    } catch (err) {
        yield put(loginFailure())
    }
}

export function* forgot(action: IActionType<AxiosResponse<IForgot>>) {
    try {
        const { data } = action.payload
        yield apply(authService, authService.forgot, [data])
        yield put(forgotSuccess())
    } catch (err) {
        yield put(forgotFailure())
    }
}

export function* changePassword(action: IActionType<AxiosResponse<IChangePassword>>) {
    try {
        const { data } = action.payload
        yield apply(authService, authService.changePassword, [data])
        yield put(changePasswordSuccess())
        yield put(open(
            SnackBarMessageType.SUCCESS,
            'AUTH.CHANGE_PASSWORD.SNACKBAR.TITLE',
            'AUTH.CHANGE_PASSWORD.SNACKBAR.MESSAGE')
        )
    } catch (err) {
        yield put(changePasswordFailure())
    }
}

export function* getUserLogged(userId: string | undefined, type: TypesUser | undefined) {
    if (type === TypesUser.ADMINISTRATOR) {
        yield put(changeUsername('Administrador'))
    }
    if (userId && type && type !== TypesUser.ADMINISTRATOR) {
        try {
            const user: any = yield apply(userService, userService.getById, [userId, type])
            yield put(changeUsername(user.name))
        } catch (e) {
            yield put(changeUsername('...'))
        } finally {
            yield put(changeLanguage(LanguageOptions.PT_BR))
        }
    }
}

export default function* authSaga() {
    return yield all([
        takeLatest(AuthTypes.LOGIN_REQUEST, authenticate),
        takeLatest(AuthTypes.FORGOT_REQUEST, forgot),
        takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword)
    ])
}
