import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { UserTypes } from './types'
import User, { TypesUser } from '../../application/models/users/users'

/* Action for reset state */
export const resetCreate = () => action(UserTypes.RESET_CREATE)

export const resetList = () => action(UserTypes.RESET_LIST)

/* Actions for change user */
export const changeUser = (user: User) => action(UserTypes.CHANGE_USER, { user })

export const changePaginator = (userType: TypesUser, paginator?: IPaginator) => [
    action(UserTypes.CHANGE_PAGINATOR, { userType, paginator }),
    loadRequest(userType, paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    UserTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveDialog = (dialog: boolean, id: string) => action(
    UserTypes.CHANGE_REMOVE_DIALOG,
    { dialog, id }
)

export const changeDialog = (dialog: boolean, user?: User) => {
    const pipe: any = []
    if (!dialog) {
        pipe.push(resetCreate())
    }
    return pipe.concat(action(UserTypes.CHANGE_DIALOG, { dialog, user }))

}

export const createRequest = (user: User) => action(UserTypes.CREATE_REQUEST, { user })

export const createSuccess = (user: User) => [
    action(UserTypes.CREATE_SUCCESS, { user }),
    resetCreate()
]

export const createFailure = () => action(UserTypes.CREATE_FAILURE)

/* Actions for load users */
export const loadRequest = (userType: TypesUser, paginator?: IPaginator) => action(
    UserTypes.LOAD_REQUEST,
    { userType, paginator }
)

export const loadSuccess = (response: IAxiosResponse<User[]>) => action(UserTypes.LOAD_SUCCESS, {
    users: response.data,
    headers: response.headers
})

export const loadFailure = () => action(UserTypes.LOAD_FAILURE)

export const loadMoreRequest = (userType: TypesUser, paginator: IPaginator) => action(
    UserTypes.LOAD_MORE_REQUEST,
    { userType, paginator }
)

export const loadMoreSuccess = (response: IAxiosResponse<User[]>) => action(
    UserTypes.LOAD_MORE_SUCCESS, {
        users: response.data,
        headers: response.headers
    })

export const loadMoreFailure = () => action(UserTypes.LOAD_MORE_FAILURE)


/* Actions for find user */
export const findRequest = (id: string, userType: TypesUser) => action(
    UserTypes.FIND_REQUEST,
    { id, userType }
)

export const findSuccess = (user: User) => action(UserTypes.FIND_SUCCESS, { user })

export const findFailure = () => action(UserTypes.FIND_FAILURE)

/* Actions for update user */
export const updateRequest = (user: User) => action(UserTypes.UPDATE_REQUEST, { user })

export const updateSuccess = (user: User) => action(UserTypes.UPDATE_SUCCESS, { user })

export const updateFailure = () => action(UserTypes.UPDATE_FAILURE)

/* Actions for remove user */
export const removeRequest = (id: string) => action(UserTypes.REMOVE_REQUEST, { id })

export const removeSuccess = (userId: string) => action(UserTypes.REMOVE_SUCCESS, { userId })

export const removeFailure = () => action(UserTypes.REMOVE_FAILURE)

/* Load total users */
export const loadTotalRequest = () => action(UserTypes.LOAD_TOTAL_REQUEST)

export const loadTotalSuccess = (
    totalCoordinators: number,
    totalSupervisors: number,
    totalOperators: number,
    totalElectricians: number
) => action(UserTypes.LOAD_TOTAL_SUCCESS, {
    totalCoordinators,
    totalSupervisors,
    totalOperators,
    totalElectricians
})

export const loadTotalFailure = () => action(UserTypes.LOAD_TOTAL_FAILURE)
