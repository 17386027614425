import { createTypes } from 'reduxsauce'
import { IComponentState } from '../root.types'
import Device from '../../application/models/call/device'

export const DevicesTypes = createTypes(`
    RESET_LIST
    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
`,
    {
        prefix: '@devices/'
    }
)

/**
 * Action types
 */
export interface IActionLoad {
    readonly installations: string[]
}

/**
 * State types
 */
export interface IListState extends IComponentState {
    readonly data: Device[]
}

export interface IDeviceState {
    readonly list: IListState
}
