import { all, apply, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { IActionType, IAxiosResponse } from '../root.types'
import { open } from '../snack.bar/actions'
import { SnackBarMessageType } from '../../../components/snackbar'
import {
    CoordinationTypes,
    IActionAssociate,
    IActionCreate,
    IActionFind,
    IActionLoad,
    IActionLoadAssociate,
    IActionRemove
} from './types'

import {
    associateFailure,
    associateSuccess,
    createFailure,
    createSuccess,
    dissociateFailure,
    dissociateSuccess,
    findFailure,
    findSuccess,
    loadFailure,
    loadLocationsFailure,
    loadLocationsSuccess,
    loadMoreFailure,
    loadMoreSuccess,
    loadRequest,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import { changeBreadCrumbLast } from '../layout/actions'
import coordinationService from '../../../services/coordination'
import ServiceLocation from '../../application/models/regional.center/service.location'

function* create(action: IActionType<IActionCreate>) {
    try {
        const { coordination } = action.payload
        const response: any = yield apply(coordinationService, coordinationService.create, [coordination])
        yield put<any>(createSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.COORDINATION.CREATE.MESSAGE'))
        yield call(reloadCoordinations)
    } catch (e) {
        yield put(createFailure())
    }
}

function* getAll(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(
            coordinationService,
            coordinationService.getAll,
            [paginator]
        )
        yield put<any>(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

function* getById(action: IActionType<IActionFind>) {
    yield put(changeBreadCrumbLast(''))
    const { id } = action.payload
    try {
        const response: any = yield apply(coordinationService, coordinationService.getById, [id])
        yield put(findSuccess(response))
        yield put(changeBreadCrumbLast(response?.name))
    } catch (e) {
        yield put(findFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    try {
        const { coordination } = action.payload
        const response: any = yield apply(coordinationService, coordinationService.update, [coordination])
        yield put<any>(updateSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.COORDINATION.UPDATE.MESSAGE'))
        yield call(reloadCoordinations)
    } catch (e) {
        yield put(updateFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(coordinationService, coordinationService.remove, [id])
        yield put<any>(removeSuccess(id))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.COORDINATION.REMOVE.MESSAGE'))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* loadMore(action: IActionType<IActionLoad>) {
    try {
        const { paginator } = action.payload
        const response: IAxiosResponse<any[]> = yield apply(
            coordinationService,
            coordinationService.getAll,
            [paginator]
        )
        yield put<any>(loadMoreSuccess(response))
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* reloadCoordinations() {
    yield put<any>(loadRequest())
}

function* loadAssociates(action: IActionType<IActionLoadAssociate>) {
    const { coordinationId } = action.payload
    try {
        const response: IAxiosResponse<ServiceLocation[]> = yield apply(
            coordinationService,
            coordinationService.getAllLocationsByCoordinationId,
            [coordinationId]
        )
        yield put<any>(loadLocationsSuccess(response))
    } catch (e) {
        yield put(loadLocationsFailure())
    }
}

function* associateServiceLocation(action: IActionType<IActionAssociate>) {
    const { coordinationId, locationId } = action.payload
    try {
        yield apply(
            coordinationService,
            coordinationService.associateServiceLocation,
            [coordinationId, locationId]
        )
        yield put<any>(associateSuccess(coordinationId, locationId))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.COORDINATION.ASSOCIATE.MESSAGE'))
    } catch (e) {
        yield put(associateFailure())
    }
}

function* dissociateServiceLocation(action: IActionType<IActionAssociate>) {
    const { coordinationId, locationId } = action.payload
    try {
        yield apply(
            coordinationService,
            coordinationService.dissociateServiceLocation,
            [coordinationId, locationId]
        )
        yield put<any>(dissociateSuccess(coordinationId, locationId))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.COORDINATION.DISSOCIATE.MESSAGE'))
    } catch (e) {
        yield put(dissociateFailure())
    }
}

export default function* CoordinationSaga() {
    return yield all([
        takeLatest(CoordinationTypes.CREATE_REQUEST, create),
        takeLatest(CoordinationTypes.LOAD_REQUEST, getAll),
        takeLatest(CoordinationTypes.FIND_REQUEST, getById),
        takeLatest(CoordinationTypes.UPDATE_REQUEST, update),
        takeLatest(CoordinationTypes.REMOVE_REQUEST, remove),
        takeEvery(CoordinationTypes.LOAD_MORE_REQUEST, loadMore),
        takeEvery(CoordinationTypes.LOAD_LOCATIONS_REQUEST, loadAssociates),
        takeEvery(CoordinationTypes.ASSOCIATE_LOCATION_REQUEST, associateServiceLocation),
        takeEvery(CoordinationTypes.DISSOCIATE_LOCATION_REQUEST, dissociateServiceLocation)
    ])
}
