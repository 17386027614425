import { Reducer } from 'redux'
import { IReportState, ReportTypes } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, request, success } from '../root.types'
import Call from '../../application/models/call/call'

export const INITIAL_STATE: IReportState = {
    list: {
        calls: [],
        loading: false,
        success: false,
        error: false
    }
}

export const loadRequest = (state: IReportState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (state: IReportState = INITIAL_STATE, action: IActionType<{
    calls: Call[],
    headers
}>) => {
    const { calls } = action.payload
    return { ...state, list: success({ ...state.list, calls }) }
}

export const loadFailure = (state: IReportState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

const reducer: Reducer<IReportState> = createReducer<IReportState>(INITIAL_STATE, {
    [ReportTypes.LOAD_REQUEST]: loadRequest,
    [ReportTypes.LOAD_SUCCESS]: loadSuccess,
    [ReportTypes.LOAD_FAILURE]: loadFailure
})

export default reducer
