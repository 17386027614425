import { Reducer } from 'redux'
import { CenterTypes, IRegionalCenterState } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../root.types'
import RegionalCenter from '../../application/models/regional.center/regional.center'
import User, { TypesUser } from '../../application/models/users/users'
import Coordination from '../../application/models/regional.center/coordination'
import ServiceLocation from '../../application/models/regional.center/service.location'
import Indicator from '../../application/models/regional.center/indicator'

export const INITIAL_STATE: IRegionalCenterState = {
    create: {
        center: new RegionalCenter(),
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    list: {
        centers: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        dialog: false,
        id: '',
        loading: false,
        success: false,
        error: false
    },
    operators: {
        users: [],
        loading: false,
        success: false,
        error: false,
        associating: false,
        dialogAssociate: false,
        dissociating: false,
        dialogDissociate: false,
        dissociateId: '',
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    supervisors: {
        users: [],
        loading: false,
        success: false,
        error: false,
        associating: false,
        dialogAssociate: false,
        dissociating: false,
        dialogDissociate: false,
        dissociateId: '',
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    electricians: {
        users: [],
        loading: false,
        success: false,
        error: false,
        associating: false,
        dialogAssociate: false,
        dissociating: false,
        dialogDissociate: false,
        dissociateId: '',
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    coordinations: {
        coordinations: [],
        loading: false,
        success: false,
        error: false,
        associating: false,
        dialogAssociate: false,
        dissociating: false,
        dialogDissociate: false,
        dissociateId: '',
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    locations: {
        locations: [],
        loading: false,
        success: false,
        error: false
    },
    selected: new RegionalCenter(),
    servicesDispatches: {
        servicesDispatches: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    monthYear: new Date(),
    iri: {
        data: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    indicators: {
        data: new Indicator(),
        loading: false,
        success: false,
        error: false
    }
}

export const resetCreate = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, create: INITIAL_STATE.create }
}

export const resetList = (state: IRegionalCenterState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const changeCenter = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    center: RegionalCenter
}>) => {
    const { center } = action.payload
    return { ...state, create: { ...state.create, center } }
}

export const changePaginator = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    paginator?: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, list: { ...state.list, paginator } }
}

export const changeSearchPaginator = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    search: ISearch
}>) => {
    const { search } = action.payload
    return {
        ...state,
        list: {
            ...state.list,
            paginator: {
                ...state.list.paginator,
                search
            }
        }
    }
}

export const changeRemoveDialog = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    id: string
}>) => {
    const { dialog, id } = action.payload
    return { ...state, remove: { ...state.remove, dialog, id } }
}

export const changeDialog = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    center: RegionalCenter
}>) => {
    const { dialog, center } = action.payload
    return { ...state, create: { ...state.create, dialog, center: center || state.create.center } }
}

export const createRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const createSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    center: RegionalCenter
}>) => {
    const { center } = action.payload
    return {
        ...state,
        create: success({ ...state.create, center, dialog: true })
    }
}

export const createFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, create: failure(state.create) }
}

export const loadRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    centers: RegionalCenter[],
    headers
}>) => {
    const { centers, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, list: success({ ...state.list, centers, paginator }) }
}

export const loadFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreRequest = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    paginator: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, list: request({ ...state.list, paginator }) }
}

export const loadMoreSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    centers: RegionalCenter[],
    headers
}>) => {
    const { centers, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    const stateCenters = state.list.centers.concat(centers)
    return {
        ...state,
        list: success({ ...state.list, centers: stateCenters, paginator })
    }
}

export const updateSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    center: RegionalCenter
}>) => {
    const { center } = action.payload
    return { ...state, create: success({ ...state.create, center }) }
}

export const removeRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, remove: request({ ...state.remove, dialog: true }) }
}

export const removeSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    id: string
}>) => {
    const { id } = action.payload
    const centers = state.list.centers?.filter(center => center?.id !== id)
    return {
        ...state,
        list: {
            ...state.list,
            centers
        },
        remove: success({ ...INITIAL_STATE.remove })
    }
}

export const removeFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, remove: failure(state.remove) }
}

export const loadAssociatesRequest = (state: IRegionalCenterState = INITIAL_STATE, actions: IActionType<{ userType: TypesUser }>) => {
    const { userType } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: request(state.operators) }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: request(state.supervisors) }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: request(state.electricians) }

        default:
            return state
    }
}

export const loadAssociatesSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    userType: TypesUser,
    users: User[],
    headers
}>) => {
    const { users, userType, headers } = action.payload
    const totalRecords = parseInt(headers['x-total-count'], 10)
    switch (userType) {
        case TypesUser.OPERATOR:
            const operatorsPaginator = {
                ...state.list.paginator,
                totalRecords
            }
            return { ...state, operators: success({ ...state.operators, users, paginator: operatorsPaginator }) }

        case TypesUser.SUPERVISOR:
            const supervisorPaginator = {
                ...state.list.paginator,
                totalRecords
            }
            return { ...state, supervisors: success({ ...state.supervisors, users, paginator: supervisorPaginator }) }

        case TypesUser.ELECTRICIAN:
            const electricianPaginator = {
                ...state.list.paginator,
                totalRecords
            }
            return {
                ...state,
                electricians: success({ ...state.electricians, users, paginator: electricianPaginator })
            }

        default:
            return state
    }
}

export const loadAssociatesFailure = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{ userType: TypesUser }>) => {
    const { userType } = action.payload

    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: failure({ ...state.operators }) }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: failure({ ...state.supervisors }) }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: failure({ ...state.electricians }) }

        default:
            return state
    }
}

export const loadMoreAssociatesRequest = (state: IRegionalCenterState = INITIAL_STATE, actions: IActionType<{
    userType: TypesUser,
    paginator: IPaginator
}>) => {
    const { userType, paginator } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: request({ ...state.operators, paginator }) }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: request({ ...state.supervisors, paginator }) }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: request({ ...state.electricians, paginator }) }

        default:
            return state
    }
}

export const loadMoreAssociatesSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    userType: TypesUser,
    users: User[],
    headers
}>) => {
    const { users, userType, headers } = action.payload
    const totalRecords = parseInt(headers['x-total-count'], 10)
    const supervisors = state.supervisors.users
    const operators = state.operators.users
    const electricians = state.electricians.users

    switch (userType) {
        case TypesUser.OPERATOR:
            const operatorsPaginator = {
                ...state.list.paginator,
                totalRecords
            }
            return {
                ...state,
                operators: success({
                    ...state.operators,
                    users: operators.concat(users),
                    paginator: operatorsPaginator
                })
            }

        case TypesUser.SUPERVISOR:
            const supervisorPaginator = {
                ...state.list.paginator,
                totalRecords
            }
            return {
                ...state,
                supervisors: success({
                    ...state.supervisors,
                    users: supervisors.concat(users),
                    paginator: supervisorPaginator
                })
            }

        case TypesUser.ELECTRICIAN:
            const electricianPaginator = {
                ...state.list.paginator,
                totalRecords
            }
            return {
                ...state,
                electricians: success({
                    ...state.electricians,
                    users: electricians.concat(users),
                    paginator: electricianPaginator
                })
            }

        default:
            return state
    }
}

export const changeAssociatePaginator = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    userType: TypesUser,
    paginator?: IPaginator
}>) => {
    const { paginator, userType } = action.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: success({ ...state.operators, paginator }) }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: success({ ...state.supervisors, paginator }) }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: success({ ...state.electricians, paginator }) }

        default:
            return state
    }
}

export const changeAssociateSearchPaginator = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    userType: TypesUser,
    search: ISearch
}>) => {
    const { search, userType } = action.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: { ...state.operators, paginator: { ...state.operators.paginator, search } } }

        case TypesUser.SUPERVISOR:
            return {
                ...state,
                supervisors: { ...state.supervisors, paginator: { ...state.supervisors.paginator, search } }
            }

        case TypesUser.ELECTRICIAN:
            return {
                ...state,
                electricians: { ...state.electricians, paginator: { ...state.electricians.paginator, search } }
            }

        default:
            return state
    }
}

export const associateUserRequest = (state: IRegionalCenterState = INITIAL_STATE, actions: IActionType<{ userType: TypesUser }>) => {
    const { userType } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: { ...state.operators, associating: true } }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: { ...state.supervisors, associating: true } }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: { ...state.electricians, associating: true } }

        default:
            return state
    }
}

export const associateUserSuccess = (
    state: IRegionalCenterState = INITIAL_STATE,
    actions: IActionType<{ userType: TypesUser, user: User }>) => {
    const { userType, user } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return {
                ...state,
                operators: {
                    ...state.operators,
                    users: [user].concat(state.operators.users),
                    associating: false,
                    dialogAssociate: false
                }
            }

        case TypesUser.SUPERVISOR:
            return {
                ...state,
                supervisors: {
                    ...state.supervisors,
                    users: [user].concat(state.supervisors.users),
                    associating: false,
                    dialogAssociate: false
                }
            }

        case TypesUser.ELECTRICIAN:
            return {
                ...state,
                electricians: {
                    ...state.electricians,
                    users: [user].concat(state.electricians.users),
                    associating: false,
                    dialogAssociate: false
                }
            }

        default:
            return state
    }
}

export const associateUserEnded = (state: IRegionalCenterState = INITIAL_STATE, actions: IActionType<{ userType: TypesUser }>) => {
    const { userType } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: { ...state.operators, associating: false, dialogAssociate: false } }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: { ...state.supervisors, associating: false, dialogAssociate: false } }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: { ...state.electricians, associating: false, dialogAssociate: false } }

        default:
            return state
    }
}

export const dissociateUserRequest = (state: IRegionalCenterState = INITIAL_STATE, actions: IActionType<{ userType: TypesUser }>) => {
    const { userType } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: { ...state.operators, dissociating: true } }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: { ...state.supervisors, dissociating: true } }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: { ...state.electricians, dissociating: true } }

        default:
            return state
    }
}

export const dissociateUserSuccess = (
    state: IRegionalCenterState = INITIAL_STATE,
    actions: IActionType<{ userType: TypesUser, user: User }>) => {
    const { userType, user } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return {
                ...state,
                operators: {
                    ...state.operators,
                    users: state.operators.users.filter((elementUser: User) => elementUser?.id !== user?.id),
                    dissociating: false,
                    dialogDissociate: false
                }
            }

        case TypesUser.SUPERVISOR:
            return {
                ...state,
                supervisors: {
                    ...state.supervisors,
                    users: state.supervisors.users.filter((elementUser: User) => elementUser?.id !== user?.id),
                    dissociating: false,
                    dialogDissociate: false
                }
            }

        case TypesUser.ELECTRICIAN:
            return {
                ...state,
                electricians: {
                    ...state.electricians,
                    users: state.electricians.users.filter((elementUser: User) => elementUser?.id !== user?.id),
                    dissociating: false,
                    dialogDissociate: false
                }
            }

        default:
            return state
    }
}

export const dissociateUserEnded = (state: IRegionalCenterState = INITIAL_STATE, actions: IActionType<{ userType: TypesUser }>) => {
    const { userType } = actions.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return { ...state, operators: { ...state.operators, dissociating: false, dialogDissociate: false } }

        case TypesUser.SUPERVISOR:
            return { ...state, supervisors: { ...state.supervisors, dissociating: false, dialogDissociate: false } }

        case TypesUser.ELECTRICIAN:
            return { ...state, electricians: { ...state.electricians, dissociating: false, dialogDissociate: false } }

        default:
            return state
    }
}

export const changeAssociateDialog = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    userType?: TypesUser
}>) => {
    const { dialog, userType } = action.payload
    switch (userType) {
        case TypesUser.OPERATOR:
            return {
                ...state,
                operators: {
                    ...state.operators,
                    dialogAssociate: dialog
                }
            }

        case TypesUser.SUPERVISOR:
            return {
                ...state,
                supervisors: {
                    ...state.supervisors,
                    dialogAssociate: dialog
                }
            }

        case TypesUser.ELECTRICIAN:
            return {
                ...state,
                electricians: {
                    ...state.electricians,
                    dialogAssociate: dialog
                }
            }

        default:
            return state
    }
}

export const changeDissociateDialog = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    userType?: TypesUser,
    id?: string
}>) => {
    const { dialog, userType, id } = action.payload

    switch (userType) {
        case TypesUser.OPERATOR:
            return {
                ...state,
                operators: {
                    ...state.operators,
                    dialogDissociate: dialog,
                    dissociateId: id
                }
            }

        case TypesUser.SUPERVISOR:
            return {
                ...state,
                supervisors: {
                    ...state.supervisors,
                    dialogDissociate: dialog,
                    dissociateId: id
                }
            }

        case TypesUser.ELECTRICIAN:
            return {
                ...state,
                electricians: {
                    ...state.electricians,
                    dialogDissociate: dialog,
                    dissociateId: id
                }
            }

        default:
            return state
    }

}

export const changeRegionalCenterSelected = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    center: RegionalCenter
}>) => {
    const { center } = action.payload
    return { ...state, selected: center }
}

export const loadCoordinationsRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, coordinations: request(state.coordinations) }
}

export const loadCoordinationsSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    coordinations: Coordination[],
    headers
}>) => {
    const { coordinations, headers } = action.payload
    const paginator = {
        ...state.coordinations.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, coordinations: success({ ...state.coordinations, coordinations, paginator }) }
}

export const loadCoordinationsFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, coordinations: failure(state.coordinations) }
}

export const changeCoordinationPaginator = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    paginator?: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, coordinations: success({ ...state.coordinations, paginator }) }
}

export const changeCoordinationSearchPaginator = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    search: ISearch
}>) => {
    const { search } = action.payload
    return {
        ...state,
        coordinations: {
            ...state.coordinations,
            paginator: {
                ...state.coordinations.paginator,
                search
            }
        }
    }
}

export const changeCoordinationAssociateDialog = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    dialog: boolean
}>) => {
    const { dialog } = action.payload
    return {
        ...state,
        coordinations: {
            ...state.coordinations,
            dialogAssociate: dialog
        }
    }
}

export const changeCoordinationDissociateDialog = (
    state: IRegionalCenterState = INITIAL_STATE,
    action: IActionType<{
        dialog: boolean,
        coordinationId?: string
    }>) => {
    const { dialog, coordinationId } = action.payload
    return {
        ...state,
        coordinations: {
            ...state.coordinations,
            dialogDissociate: dialog,
            dissociateId: coordinationId
        }
    }
}

export const associateCoordinationRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, coordinations: { ...state.coordinations, associating: true } }
}

export const associateCoordinationSuccess = (
    state: IRegionalCenterState = INITIAL_STATE,
    action: IActionType<{ coordination: Coordination }>
) => {
    const { coordination } = action.payload
    return {
        ...state,
        coordinations: {
            ...state.coordinations,
            coordinations: [coordination].concat(state.coordinations.coordinations),
            associating: false,
            dialogAssociate: false
        }
    }
}

export const associateCoordinationEnded = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, coordinations: { ...state.coordinations, associating: false, dialogAssociate: false } }
}

export const dissociateCoordinationRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, coordinations: { ...state.coordinations, dissociating: true } }
}

export const dissociateCoordinationSuccess = (
    state: IRegionalCenterState = INITIAL_STATE,
    action: IActionType<{ coordinationId: string }>
) => {
    const { coordinationId } = action.payload
    return {
        ...state,
        coordinations: {
            ...state.coordinations,
            coordinations: state
                .coordinations
                .coordinations
                .filter(coordination => coordination.id !== coordinationId),
            dissociating: false,
            dialogDissociate: false
        }
    }
}

export const dissociateCoordinationEnded = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, coordinations: { ...state.coordinations, dissociating: false, dialogDissociate: false } }
}

export const serviceDispatchesRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, servicesDispatches: request(state.servicesDispatches) }
}

export const serviceDispatchesSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    servicesDispatches: any[],
    headers
}>) => {
    const { servicesDispatches, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, servicesDispatches: success({ ...state.servicesDispatches, servicesDispatches, paginator }) }
}

export const serviceDispatchesFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, servicesDispatches: failure(state.servicesDispatches) }
}

export const changeMonthYear = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    monthYear: Date
}>) => {
    const { monthYear } = action.payload
    return { ...state, monthYear }
}

export const loadServiceLocationsRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, locations: request(state.locations) }
}

export const loadServiceLocationsSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    locations: ServiceLocation[]
}>) => {
    const { locations } = action.payload
    return { ...state, locations: success({ ...state.locations, locations }) }
}


export const loadServiceLocationsFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, locations: failure(state.locations) }
}


export const loadIriRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, iri: request(state.iri) }
}

export const loadIriSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    data: any[],
    headers: object
}>) => {
    const { data, headers } = action.payload
    const paginator = {
        ...state.iri.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, iri: success({ ...state.iri, data, paginator }) }
}


export const loadIriFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, iri: failure(state.iri) }
}

export const resetIri = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, iri: INITIAL_STATE.iri }
}

export const indicatorsRequest = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, indicators: request(state.indicators) }
}

export const indicatorsSuccess = (state: IRegionalCenterState = INITIAL_STATE, action: IActionType<{
    indicators: Indicator
}>) => {
    const { indicators } = action.payload
    return {
        ...state,
        indicators: success({ ...state.indicators, data: indicators })
    }
}

export const indicatorsFailure = (state: IRegionalCenterState = INITIAL_STATE) => {
    return { ...state, indicators: failure({ ...state.indicators, data: new Indicator() }) }
}

const reducer: Reducer<IRegionalCenterState> = createReducer<IRegionalCenterState>(INITIAL_STATE, {
    [CenterTypes.RESET_CREATE]: resetCreate,
    [CenterTypes.RESET_LIST]: resetList,

    [CenterTypes.CHANGE_CENTER]: changeCenter,
    [CenterTypes.CHANGE_PAGINATOR]: changePaginator,
    [CenterTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,
    [CenterTypes.CHANGE_REMOVE_DIALOG]: changeRemoveDialog,
    [CenterTypes.CHANGE_DIALOG]: changeDialog,

    [CenterTypes.CREATE_REQUEST]: createRequest,
    [CenterTypes.CREATE_SUCCESS]: createSuccess,
    [CenterTypes.CREATE_FAILURE]: createFailure,

    [CenterTypes.LOAD_REQUEST]: loadRequest,
    [CenterTypes.LOAD_SUCCESS]: loadSuccess,
    [CenterTypes.LOAD_FAILURE]: loadFailure,

    [CenterTypes.LOAD_MORE_REQUEST]: loadMoreRequest,
    [CenterTypes.LOAD_MORE_SUCCESS]: loadMoreSuccess,
    [CenterTypes.LOAD_MORE_FAILURE]: loadFailure,

    [CenterTypes.FIND_REQUEST]: createRequest,
    [CenterTypes.FIND_SUCCESS]: updateSuccess,
    [CenterTypes.FIND_FAILURE]: createFailure,

    [CenterTypes.UPDATE_REQUEST]: createRequest,
    [CenterTypes.UPDATE_SUCCESS]: updateSuccess,
    [CenterTypes.UPDATE_FAILURE]: createFailure,

    [CenterTypes.REMOVE_REQUEST]: removeRequest,
    [CenterTypes.REMOVE_SUCCESS]: removeSuccess,
    [CenterTypes.REMOVE_FAILURE]: removeFailure,

    [CenterTypes.LOAD_ASSOCIATES_REQUEST]: loadAssociatesRequest,
    [CenterTypes.LOAD_ASSOCIATES_SUCCESS]: loadAssociatesSuccess,
    [CenterTypes.LOAD_ASSOCIATES_FAILURE]: loadAssociatesFailure,

    [CenterTypes.LOAD_MORE_ASSOCIATES_REQUEST]: loadMoreAssociatesRequest,
    [CenterTypes.LOAD_MORE_ASSOCIATES_SUCCESS]: loadMoreAssociatesSuccess,
    [CenterTypes.LOAD_MORE_ASSOCIATES_FAILURE]: loadAssociatesFailure,

    [CenterTypes.CHANGE_ASSOCIATES_PAGINATOR]: changeAssociatePaginator,
    [CenterTypes.CHANGE_ASSOCIATES_SEARCH_PAGINATOR]: changeAssociateSearchPaginator,

    [CenterTypes.ASSOCIATE_USER_REQUEST]: associateUserRequest,
    [CenterTypes.ASSOCIATE_USER_SUCCESS]: associateUserSuccess,
    [CenterTypes.ASSOCIATE_USER_FAILURE]: associateUserEnded,
    [CenterTypes.CHANGE_ASSOCIATE_DIALOG]: changeAssociateDialog,

    [CenterTypes.DISSOCIATE_USER_REQUEST]: dissociateUserRequest,
    [CenterTypes.DISSOCIATE_USER_SUCCESS]: dissociateUserSuccess,
    [CenterTypes.DISSOCIATE_USER_FAILURE]: dissociateUserEnded,
    [CenterTypes.CHANGE_DISSOCIATE_DIALOG]: changeDissociateDialog,

    [CenterTypes.LOAD_CENTERS_BY_USER_REQUEST]: loadRequest,
    [CenterTypes.LOAD_CENTERS_BY_USER_SUCCESS]: loadSuccess,
    [CenterTypes.LOAD_CENTERS_BY_USER_FAILURE]: loadFailure,

    [CenterTypes.CHANGE_REGIONAL_SELECTED]: changeRegionalCenterSelected,

    [CenterTypes.LOAD_COORDINATIONS_REQUEST]: loadCoordinationsRequest,
    [CenterTypes.LOAD_COORDINATIONS_SUCCESS]: loadCoordinationsSuccess,
    [CenterTypes.LOAD_COORDINATIONS_FAILURE]: loadCoordinationsFailure,

    [CenterTypes.CHANGE_COORDINATIONS_PAGINATOR]: changeCoordinationPaginator,
    [CenterTypes.CHANGE_COORDINATIONS_SEARCH_PAGINATOR]: changeCoordinationSearchPaginator,

    [CenterTypes.CHANGE_COORDINATIONS_DIALOG_ASSOCIATE]: changeCoordinationAssociateDialog,
    [CenterTypes.CHANGE_COORDINATIONS_DIALOG_DISSOCIATE]: changeCoordinationDissociateDialog,

    [CenterTypes.ASSOCIATE_COORDINATIONS_REQUEST]: associateCoordinationRequest,
    [CenterTypes.ASSOCIATE_COORDINATIONS_SUCCESS]: associateCoordinationSuccess,
    [CenterTypes.ASSOCIATE_COORDINATIONS_FAILURE]: associateCoordinationEnded,

    [CenterTypes.DISSOCIATE_COORDINATIONS_REQUEST]: dissociateCoordinationRequest,
    [CenterTypes.DISSOCIATE_COORDINATIONS_SUCCESS]: dissociateCoordinationSuccess,
    [CenterTypes.DISSOCIATE_COORDINATIONS_FAILURE]: dissociateCoordinationEnded,

    [CenterTypes.SERVICES_DISPATCH_REQUEST]: serviceDispatchesRequest,
    [CenterTypes.SERVICES_DISPATCH_SUCCESS]: serviceDispatchesSuccess,
    [CenterTypes.SERVICES_DISPATCH_FAILURE]: serviceDispatchesFailure,
    [CenterTypes.CHANGE_MONTH_YEAR]: changeMonthYear,

    [CenterTypes.LOAD_LOCATIONS_REQUEST]: loadServiceLocationsRequest,
    [CenterTypes.LOAD_LOCATIONS_SUCCESS]: loadServiceLocationsSuccess,
    [CenterTypes.LOAD_LOCATIONS_FAILURE]: loadServiceLocationsFailure,

    [CenterTypes.LOAD_IRI_REQUEST]: loadIriRequest,
    [CenterTypes.LOAD_IRI_BY_USER_REQUEST]: loadIriRequest,
    [CenterTypes.LOAD_IRI_SUCCESS]: loadIriSuccess,
    [CenterTypes.LOAD_IRI_FAILURE]: loadIriFailure,
    [CenterTypes.RESET_IRI]: resetIri,

    [CenterTypes.SAVE_INDICATORS_REQUEST]: indicatorsRequest,
    [CenterTypes.SAVE_INDICATORS_SUCCESS]: indicatorsSuccess,
    [CenterTypes.SAVE_INDICATORS_FAILURE]: indicatorsFailure,

    [CenterTypes.LOAD_INDICATORS_REQUEST]: indicatorsRequest,
    [CenterTypes.LOAD_INDICATORS_SUCCESS]: indicatorsSuccess,
    [CenterTypes.LOAD_INDICATORS_FAILURE]: indicatorsFailure
})

export default reducer
