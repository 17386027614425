import React, { Component } from 'react'

import { Provider } from 'react-redux'

import store from './store'
import ThemeProviderComponent from './theme.provider'

class App extends Component {

    public render() {
        const commonStyle = 'font-weight: bold;font-style: italic;font-style: italic;'
        const style1 = `${commonStyle};font-size: 40px;color: #00a594;`
        const style2 = `${commonStyle};font-size: 12px;padding-left: 5px;color: #555;`

        console.log('%cPRIORIZE%cby NUTES/UEPB', style1, style2)

        return <Provider store={store}>
            <ThemeProviderComponent/>
        </Provider>
    }
}

export default App
