import { JsonUtils } from '../../utils/json.util'

export enum CallStatus {
    FOUNDED = 'founded',
    UNFOUNDED = 'unfounded'
}

export default class Call {
    private _region: string | undefined
    private _service_number: string | undefined
    private _priority: string | undefined
    private _substation: string | undefined
    private _dh_complains: string | undefined
    private _dh_scheduled: string | undefined
    private _dh_designates: string | undefined
    private _dh_activate: string | undefined
    private _dh_location: string | undefined
    private _dh_terreal: string | undefined
    private _service_type: string | undefined
    private _address: string | undefined
    private _affected_device: string | undefined
    private _proceed: string | undefined
    private _consumer_registration: number | undefined
    private _consumer_name: string | undefined
    private _cpf_cnpj: string | undefined
    private _phone: string | undefined
    private _recidivism: number | undefined
    private _last_opr: string | undefined
    private _first_opr: string | undefined
    private _closing_code: string | undefined
    private _team1: string | undefined
    private _team2: string | undefined

    get region(): string | undefined {
        return this._region
    }

    set region(value: string | undefined) {
        this._region = value
    }

    get service_number(): string | undefined {
        return this._service_number
    }

    set service_number(value: string | undefined) {
        this._service_number = value
    }

    get priority(): string | undefined {
        return this._priority
    }

    set priority(value: string | undefined) {
        this._priority = value
    }

    get substation(): string | undefined {
        return this._substation
    }

    set substation(value: string | undefined) {
        this._substation = value
    }

    get dh_complains(): string | undefined {
        return this._dh_complains
    }

    set dh_complains(value: string | undefined) {
        this._dh_complains = value
    }

    get dh_scheduled(): string | undefined {
        return this._dh_scheduled
    }

    set dh_scheduled(value: string | undefined) {
        this._dh_scheduled = value
    }

    get dh_designates(): string | undefined {
        return this._dh_designates
    }

    set dh_designates(value: string | undefined) {
        this._dh_designates = value
    }

    get dh_activate(): string | undefined {
        return this._dh_activate
    }

    set dh_activate(value: string | undefined) {
        this._dh_activate = value
    }

    get dh_location(): string | undefined {
        return this._dh_location
    }

    set dh_location(value: string | undefined) {
        this._dh_location = value
    }

    get dh_terreal(): string | undefined {
        return this._dh_terreal
    }

    set dh_terreal(value: string | undefined) {
        this._dh_terreal = value
    }

    get service_type(): string | undefined {
        return this._service_type
    }

    set service_type(value: string | undefined) {
        this._service_type = value
    }

    get address(): string | undefined {
        return this._address
    }

    set address(value: string | undefined) {
        this._address = value
    }

    get affected_device(): string | undefined {
        return this._affected_device
    }

    set affected_device(value: string | undefined) {
        this._affected_device = value
    }

    get proceed(): string | undefined {
        return this._proceed
    }

    set proceed(value: string | undefined) {
        this._proceed = value
    }

    get consumer_registration(): number | undefined {
        return this._consumer_registration
    }

    set consumer_registration(value: number | undefined) {
        this._consumer_registration = value
    }

    get consumer_name(): string | undefined {
        return this._consumer_name
    }

    set consumer_name(value: string | undefined) {
        this._consumer_name = value
    }

    get cpf_cnpj(): string | undefined {
        return this._cpf_cnpj
    }

    set cpf_cnpj(value: string | undefined) {
        this._cpf_cnpj = value
    }

    get phone(): string | undefined {
        return this._phone
    }

    set phone(value: string | undefined) {
        this._phone = value
    }

    get recidivism(): number | undefined {
        return this._recidivism
    }

    set recidivism(value: number | undefined) {
        this._recidivism = value
    }

    get last_opr(): string | undefined {
        return this._last_opr
    }

    set last_opr(value: string | undefined) {
        this._last_opr = value
    }

    get first_opr(): string | undefined {
        return this._first_opr
    }

    set first_opr(value: string | undefined) {
        this._first_opr = value
    }

    get closing_code(): string | undefined {
        return this._closing_code
    }

    set closing_code(value: string | undefined) {
        this._closing_code = value
    }

    get team1(): string | undefined {
        return this._team1
    }

    set team1(value: string | undefined) {
        this._team1 = value
    }

    get team2(): string | undefined {
        return this._team2
    }

    set team2(value: string | undefined) {
        this._team2 = value
    }

    public fromJSON(json: any): Call {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.region !== undefined) {
            this.region = json.region
        }

        if (json.service_number !== undefined) {
            this.service_number = json.service_number
        }

        if (json.priority !== undefined) {
            this.priority = json.priority
        }

        if (json.substation !== undefined) {
            this.substation = json.substation
        }

        if (json.dh_complains !== undefined) {
            this.dh_complains = json.dh_complains
        }

        if (json.dh_scheduled !== undefined) {
            this.dh_scheduled = json.dh_scheduled
        }

        if (json.dh_designates !== undefined) {
            this.dh_designates = json.dh_designates
        }

        if (json.dh_activate !== undefined) {
            this.dh_activate = json.dh_activate
        }

        if (json.dh_location !== undefined) {
            this.dh_location = json.dh_location
        }

        if (json.dh_terreal !== undefined) {
            this.dh_terreal = json.dh_terreal
        }

        if (json.service_type !== undefined) {
            this.service_type = json.service_type
        }

        if (json.address !== undefined) {
            this.address = json.address
        }

        if (json.affected_device !== undefined) {
            this.affected_device = json.affected_device
        }

        if (json.proceed !== undefined) {
            this.proceed = json.proceed
        }

        if (json.consumer_registration !== undefined) {
            this.consumer_registration = json.consumer_registration
        }

        if (json.consumer_name !== undefined) {
            this.consumer_name = json.consumer_name
        }

        if (json.cpf_cnpj !== undefined) {
            this.cpf_cnpj = json.cpf_cnpj
        }

        if (json.phone !== undefined) {
            this.phone = json.phone
        }

        if (json.recidivism !== undefined) {
            this.recidivism = json.recidivism
        }

        if (json.last_opr !== undefined) {
            this.last_opr = json.last_opr
        }

        if (json.first_opr !== undefined) {
            this.first_opr = json.first_opr
        }

        if (json.closing_code !== undefined) {
            this.closing_code = json.closing_code
        }

        if (json.team1 !== undefined) {
            this.team1 = json.team1
        }

        if (json.team2 !== undefined) {
            this.team2 = json.team2
        }

        return this
    }

    public toJSON(): any {
        return {
            region: this.region || undefined,
            service_number: this.service_number || undefined,
            priority: this.priority || undefined,
            substation: this.substation || undefined,
            dh_complains: this.dh_complains || undefined,
            dh_scheduled: this.dh_scheduled || undefined,
            dh_designates: this.dh_designates || undefined,
            dh_activate: this.dh_activate || undefined,
            dh_location: this.dh_location || undefined,
            dh_terreal: this.dh_terreal || undefined,
            service_type: this.service_type || undefined,
            address: this.address || undefined,
            affected_device: this.affected_device || undefined,
            proceed: this.proceed || undefined,
            consumer_registration: this.consumer_registration || undefined,
            consumer_name: this.consumer_name || undefined,
            cpf_cnpj: this.cpf_cnpj || undefined,
            phone: this.phone || undefined,
            recidivism: this.recidivism || undefined,
            last_opr: this.last_opr || undefined,
            first_opr: this.first_opr || undefined,
            closing_code: this.closing_code || undefined,
            team1: this.team1 || undefined,
            team2: this.team2 || undefined
        }
    }

    public cpjCnpjFormatted(): string {
        const str = `${this.cpf_cnpj}`
        if (str?.length === 11) {
            return `${str.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}`
        }
        if (str?.length === 14) {
            return `${str.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}`
        }
        return ' - - '
    }

    public isFounded(): boolean {
        const code = this._closing_code?.slice(0, 3)
        if (!code) {
            return false
        }
        switch (code) {
            case '8.82':
            case '8.84':
            case '8.85':
            case '8.88':
            case '8.90':
                return false

            default:
                return true

        }
    }
}
