import { Reducer } from 'redux'
import { ServiceLocationTypes, IServiceLocationState } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../root.types'
import ServiceLocation from '../../application/models/regional.center/service.location'

export const INITIAL_STATE: IServiceLocationState = {
    create: {
        location: new ServiceLocation(),
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    list: {
        locations: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        dialog: false,
        id: '',
        loading: false,
        success: false,
        error: false
    }
}

export const resetCreate = (state: IServiceLocationState = INITIAL_STATE) => {
    return { ...state, create: INITIAL_STATE.create }
}

export const resetList = (state: IServiceLocationState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const changeServiceLocation = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    location: ServiceLocation
}>) => {
    const { location } = action.payload
    return { ...state, create: { ...state.create, location } }
}

export const changePaginator = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    paginator?: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, list: { ...state.list, paginator } }
}

export const changeSearchPaginator = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    search: ISearch
}>) => {
    const { search } = action.payload
    return {
        ...state,
        list: {
            ...state.list,
            paginator: {
                ...state.list.paginator,
                search
            }
        }
    }
}

export const changeRemoveDialog = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    id: string
}>) => {
    const { dialog, id } = action.payload
    return { ...state, remove: { ...state.remove, dialog, id } }
}

export const changeDialog = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    location: ServiceLocation
}>) => {
    const { dialog, location } = action.payload
    return {
        ...state,
        create: {
            ...state.create,
            dialog,
            location: location || state.create.location
        }
    }
}

export const createRequest = (state: IServiceLocationState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const createSuccess = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    location: ServiceLocation
}>) => {
    const { location } = action.payload
    return {
        ...state,
        create: success({ ...state.create, location, dialog: true })
    }
}

export const createFailure = (state: IServiceLocationState = INITIAL_STATE) => {
    return { ...state, create: failure(state.create) }
}

export const loadRequest = (state: IServiceLocationState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    locations: ServiceLocation[],
    headers
}>) => {
    const { locations, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return { ...state, list: success({ ...state.list, locations, paginator }) }
}

export const loadFailure = (state: IServiceLocationState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreRequest = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    paginator: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, list: request({ ...state.list, paginator }) }
}

export const loadMoreSuccess = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    locations: ServiceLocation[],
    headers
}>) => {
    const { locations, headers } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    const stateLocations = state.list.locations.concat(locations)
    return {
        ...state,
        list: success({ ...state.list, locations: stateLocations, paginator })
    }
}

export const updateSuccess = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    location: ServiceLocation
}>) => {
    const { location } = action.payload
    return { ...state, create: success({ ...state.create, location }) }
}

export const removeRequest = (state: IServiceLocationState = INITIAL_STATE) => {
    return { ...state, remove: request({ ...state.remove, dialog: true }) }
}

export const removeSuccess = (state: IServiceLocationState = INITIAL_STATE, action: IActionType<{
    id: string
}>) => {
    const { id } = action.payload
    const locations = state.list.locations?.filter(location => location?.id !== id)
    return {
        ...state,
        list: {
            ...state.list,
            locations
        },
        remove: success({ ...INITIAL_STATE.remove })
    }
}

export const removeFailure = (state: IServiceLocationState = INITIAL_STATE) => {
    return { ...state, remove: failure(state.remove) }
}

const reducer: Reducer<IServiceLocationState> = createReducer<IServiceLocationState>(INITIAL_STATE, {
    [ServiceLocationTypes.RESET_CREATE]: resetCreate,
    [ServiceLocationTypes.RESET_LIST]: resetList,

    [ServiceLocationTypes.CHANGE_SERVICE_LOCATION]: changeServiceLocation,
    [ServiceLocationTypes.CHANGE_PAGINATOR]: changePaginator,
    [ServiceLocationTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,
    [ServiceLocationTypes.CHANGE_REMOVE_DIALOG]: changeRemoveDialog,
    [ServiceLocationTypes.CHANGE_DIALOG]: changeDialog,

    [ServiceLocationTypes.CREATE_REQUEST]: createRequest,
    [ServiceLocationTypes.CREATE_SUCCESS]: createSuccess,
    [ServiceLocationTypes.CREATE_FAILURE]: createFailure,

    [ServiceLocationTypes.LOAD_REQUEST]: loadRequest,
    [ServiceLocationTypes.LOAD_SUCCESS]: loadSuccess,
    [ServiceLocationTypes.LOAD_FAILURE]: loadFailure,

    [ServiceLocationTypes.LOAD_MORE_REQUEST]: loadMoreRequest,
    [ServiceLocationTypes.LOAD_MORE_SUCCESS]: loadMoreSuccess,
    [ServiceLocationTypes.LOAD_MORE_FAILURE]: loadFailure,

    [ServiceLocationTypes.FIND_REQUEST]: createRequest,
    [ServiceLocationTypes.FIND_SUCCESS]: updateSuccess,
    [ServiceLocationTypes.FIND_FAILURE]: createFailure,

    [ServiceLocationTypes.UPDATE_REQUEST]: createRequest,
    [ServiceLocationTypes.UPDATE_SUCCESS]: updateSuccess,
    [ServiceLocationTypes.UPDATE_FAILURE]: createFailure,

    [ServiceLocationTypes.REMOVE_REQUEST]: removeRequest,
    [ServiceLocationTypes.REMOVE_SUCCESS]: removeSuccess,
    [ServiceLocationTypes.REMOVE_FAILURE]: removeFailure
})

export default reducer
