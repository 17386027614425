export enum SCOPES {
    USERS_DELETE = 'us:d',

    COORDINATOR_CREATE = 'co:c',
    COORDINATOR_READ = 'co:r',
    COORDINATOR_READ_ALL = 'co:ra',
    COORDINATOR_UPDATE = 'co:u',
    COORDINATOR_RESET_PASSWORD = 'co:rp',

    SUPERVISOR_CREATE = 'su:c',
    SUPERVISOR_READ = 'su:r',
    SUPERVISOR_READ_ALL = 'su:ra',
    SUPERVISOR_UPDATE = 'su:u',
    SUPERVISOR_RESET_PASSWORD = 'su:rp',
    SUPERVISOR_ASSOCIATE = 'ass_su:c',
    SUPERVISOR_DISSOCIATE = 'ass_su:d',

    OPERATOR_CREATE = 'op:c',
    OPERATOR_READ = 'op:r',
    OPERATOR_READ_ALL = 'op:ra',
    OPERATOR_UPDATE = 'op:u',
    OPERATOR_RESET_PASSWORD = 'op:rp',
    OPERATOR_ASSOCIATE = 'ass_op:c',
    OPERATOR_DISSOCIATE = 'ass_op:d',

    ELECTRICIAN_CREATE = 'el:c',
    ELECTRICIAN_READ = 'el:r',
    ELECTRICIAN_READ_ALL = 'el:ra',
    ELECTRICIAN_UPDATE = 'el:u',
    ELECTRICIAN_RESET_PASSWORD = 'el:rp',
    ELECTRICIAN_ASSOCIATE = 'ass_el:c',
    ELECTRICIAN_DISSOCIATE = 'ass_el:d',

    REGIONAL_CREATE = 'rc:c',
    REGIONAL_READ = 'rc:r',
    REGIONAL_READ_ALL = 'rc:ra',
    REGIONAL_UPDATE = 'rc:u',
    REGIONAL_DELETE = 'rc:d',

    COORDINATION_CREATE = 'cd:c',
    COORDINATION_READ = 'cd:r',
    COORDINATION_READ_ALL = 'cd:ra',
    COORDINATION_UPDATE = 'cd:u',
    COORDINATION_DELETE = 'cd:d',
    COORDINATION_ASSOCIATE = 'ass_cd:c',
    COORDINATION_ASSOCIATE_READ = 'ass_cd:r',
    COORDINATION_DISSOCIATE = 'ass_cd:d',

    SERVICE_LOCATION_CREATE = 'sl:c',
    SERVICE_LOCATION_READ = 'sl:r',
    SERVICE_LOCATION_READ_ALL = 'sl:ra',
    SERVICE_LOCATION_UPDATE = 'sl:u',
    SERVICE_LOCATION_DELETE = 'sl:d',
    SERVICE_LOCATION_ASSOCIATE = 'ass_sl:c',
    SERVICE_LOCATION_DISSOCIATE = 'ass_sl:d',
    SERVICE_LOCATION_ASSOCIATE_READ = 'ass_sl:r'
}
