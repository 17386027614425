import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../root.types'
import { ServiceLocationTypes } from './types'
import ServiceLocation from '../../application/models/regional.center/service.location'

/* Action for reset state */
export const resetCreate = () => action(ServiceLocationTypes.RESET_CREATE)

export const resetList = () => action(ServiceLocationTypes.RESET_LIST)

/* Actions for change service location */
export const changeServiceLocation = (location: ServiceLocation) => action(
    ServiceLocationTypes.CHANGE_SERVICE_LOCATION,
    { location }
)

export const changePaginator = (paginator?: IPaginator) => [
    action(ServiceLocationTypes.CHANGE_PAGINATOR, { paginator }),
    loadRequest(paginator)
]

export const changeSearchPaginator = (search: ISearch) => action(
    ServiceLocationTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeRemoveDialog = (dialog: boolean, id: string) => action(
    ServiceLocationTypes.CHANGE_REMOVE_DIALOG,
    { dialog, id }
)

export const changeDialog = (dialog: boolean, location?: ServiceLocation) => {
    const pipe: any = []
    if (!dialog) {
        pipe.push(resetCreate())
    }
    return pipe.concat(action(ServiceLocationTypes.CHANGE_DIALOG, { dialog, location }))

}

export const createRequest = (location: ServiceLocation) => action(
    ServiceLocationTypes.CREATE_REQUEST,
    { location }
)

export const createSuccess = (location: ServiceLocation) => [
    action(ServiceLocationTypes.CREATE_SUCCESS, { location }),
    resetCreate()
]

export const createFailure = () => action(ServiceLocationTypes.CREATE_FAILURE)

/* Actions for load service location */
export const loadRequest = (paginator?: IPaginator) => action(
    ServiceLocationTypes.LOAD_REQUEST,
    { paginator }
)

export const loadSuccess = (response: IAxiosResponse<ServiceLocation[]>) => action(
    ServiceLocationTypes.LOAD_SUCCESS, {
        locations: response.data,
        headers: response.headers
    })

export const loadFailure = () => action(ServiceLocationTypes.LOAD_FAILURE)

export const loadMoreRequest = (paginator: IPaginator) => action(
    ServiceLocationTypes.LOAD_MORE_REQUEST,
    { paginator }
)

export const loadMoreSuccess = (response: IAxiosResponse<ServiceLocation[]>) => action(
    ServiceLocationTypes.LOAD_MORE_SUCCESS, {
        locations: response.data,
        headers: response.headers
    })

export const loadMoreFailure = () => action(ServiceLocationTypes.LOAD_MORE_FAILURE)


/* Actions for find service location */
export const findRequest = (id: string) => action(ServiceLocationTypes.FIND_REQUEST, { id })

export const findSuccess = (location: ServiceLocation) => action(
    ServiceLocationTypes.FIND_SUCCESS,
    { location }
)

export const findFailure = () => action(ServiceLocationTypes.FIND_FAILURE)

/* Actions for update service location */
export const updateRequest = (location: ServiceLocation) => action(
    ServiceLocationTypes.UPDATE_REQUEST,
    { location }
)

export const updateSuccess = (location: ServiceLocation) => action(
    ServiceLocationTypes.UPDATE_SUCCESS,
    { location }
)

export const updateFailure = () => action(ServiceLocationTypes.UPDATE_FAILURE)

/* Actions for remove service location */
export const removeRequest = (id: string) => action(ServiceLocationTypes.REMOVE_REQUEST, { id })

export const removeSuccess = (id: string) => action(ServiceLocationTypes.REMOVE_SUCCESS, { id })

export const removeFailure = () => action(ServiceLocationTypes.REMOVE_FAILURE)