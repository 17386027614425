import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../root.types'
import User, { TypesUser } from '../../application/models/users/users'

export const UserTypes = createTypes(`
    RESET_CREATE 
    RESET_LIST

    CHANGE_USER 
    CHANGE_PAGINATOR
    CHANGE_SEARCH_PAGINATOR
    CHANGE_REMOVE_DIALOG
    CHANGE_DIALOG 
   
    CREATE_REQUEST
    CREATE_SUCCESS
    CREATE_FAILURE

    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE

    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE

    UPDATE_REQUEST
    UPDATE_SUCCESS
    UPDATE_FAILURE

    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE
    
    LOAD_TOTAL_REQUEST
    LOAD_TOTAL_SUCCESS
    LOAD_TOTAL_FAILURE
`,
    {
        prefix: '@user/'
    }
)

/**
 * Action types
 */
export interface IActionCreate {
    readonly user: User
}

export interface IActionFind {
    readonly id: string
    readonly userType: TypesUser
}

export interface IActionLoad {
    readonly userType: TypesUser
    readonly paginator: IPaginator
}

export interface IActionRemove {
    readonly id: string
}


interface ICreateState extends IComponentState {
    readonly user: User
    readonly dialog: boolean
}

export interface IListState extends IComponentState {
    readonly users: User[]
    readonly paginator: IPaginator
}

interface IRemoveState extends IComponentState {
    readonly dialog: boolean
    readonly id: string
    readonly userType?: TypesUser
}

interface ITotalState extends IComponentState {
    readonly totalCoordinators: number
    readonly totalSupervisors: number
    readonly totalOperators: number
    readonly totalElectricians: number
}

export interface IUserState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly total: ITotalState
}
